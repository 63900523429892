import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';

import { useRouter } from '../../Shared/Router/Router';
import { AuthContext } from '../../../context/Auth/AuthProvider';
import { LocaleContext } from '../../../context/Locale/Locale';
import{ SocketContext} from "../../../context/Socket/Sockets";
import{ OrganizationContext } from "../../../context/Organization/Organization";

import Swal from '@molline/sweetalert2';
import de from "../../../components/Dashboard/tables/datatable.de.json";
import AxiosClient from "../../../utils/axios_client";
import {PermissionsWrapper, hasPermission} from "../reactPermissionsWrapper";
import useSound from 'use-sound';

import AlertsIcon from './alerts';

const $ = require('jquery');
$.DataTable = require('datatables.net');

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const UserMenu = () => {
    const [updated, setUpdated] = useState(false);
    const router = useRouter()
    const { t } = useContext(LocaleContext);
    const { user, logout, login } = useContext(AuthContext)
    const { socket } = useContext(SocketContext)
    const { organization, setOrganization, organizations, switchOrganization } = useContext(OrganizationContext)
    const [theme, setTheme] = useState('light-layout')
    const userChanged = useCompare(user)
    const socketChanged = useCompare(socket)

    const [themChangeLightSound] = useSound('/app-assets/audio/theme_change_dark.mp3', { volume: 0.15 });

    const [themChangeDarkSound] = useSound('/app-assets/audio/theme_change_light.wav', { volume: 0.15 });


    useEffect(() => {

        if(!updated) {
            let root = document.getElementsByTagName( 'html' )[0];
            if(localStorage.getItem('theme-preference') === 'dark-layout') {
                root.className += 'loaded light-layout dark-layout'
                setTheme('dark-layout')
                localStorage.setItem('theme-preference', 'dark-layout')
            } else {
                root.classList.remove('dark-layout');
                root.className += 'loaded light-layout'
                localStorage.setItem('theme-preference', 'light-layout')
                setTheme('light-layout')
            }
            setUpdated(true)
        }

    }, [user, userChanged, organization, organizations, socket, socketChanged])

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const OptionalMenuEntries = ({ children }) => {
        let childs = React.Children.toArray(children);
        if (user && childs?.length > 2) {
            return (
                <Fragment>
                    <div className="dropdown-divider"></div>
                    {children}
                </Fragment>
            )
        }
        return (<Fragment></Fragment>);
    }

    const showKey = () => {
        AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/get/key`, {})
            .then(response => {
                Swal.fire({
                    title: 'User Access Token',
                    icon: 'success',
                    text: `${response.data}`,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                })
            })
            .catch(err => {
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    text: `${err}`,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                })
            })
    }
    
    const changeTheme = () => {
        localStorage.getItem('theme-preference')
        let root = document.getElementsByTagName( 'html' )[0];
        if(!root.classList.contains('dark-layout')) {
            root.className += 'loaded light-layout dark-layout'
            setTheme('dark-layout')
            localStorage.setItem('theme-preference', 'dark-layout')
            themChangeDarkSound();
        } else {
            root.classList.remove('dark-layout');
            root.className += 'loaded light-layout'
            localStorage.setItem('theme-preference', 'light-layout')
            setTheme('light-layout')
            themChangeLightSound();
        }
    }

    const forceLogoutAll = () => {
        Swal.fire({
            icon: "error",
            title: `ALLE Benutzer ausloggen`,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'ALLE Benutzer ausloggen',
            cancelButtonText: 'Abbrechen',
            html: `<p>Damit werden alle Benutzer der Seite ausgeloggt.
            Alle Cookies und Webtokens werden gelöscht. Außerdem werden Benutzer ausgeloggt,
            die gerade online sind.
            <b>Dies schließt auch Sie mit ein!</b> </br>Wollen Sie wirklich fortfahren?</p>`, 
        })
        .then(async (results) => {
            if (!results.isConfirmed || results.isDenied || results.isDismissed) return;
            AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/user/force/logout`)
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: `Logout Fehler`,
                    allowOutsideClick: true,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Abbrechen',
                    html:  err 
                })
            })
        })
    }

    
    return (
        <ul className="nav navbar-nav align-items-center ms-auto">

            <PermissionsWrapper
                user={user}
                scopes={['search.view.any']}>

                <li className="nav-item" id="plateformSearchEngine">
                    <a className="nav-link " onClick={() => { router.push(`/tools/search`) }}>
                        <i className="fa-solid fa-search cursor-pointer tour_info_icon"
                           title="Suchfunktion"></i>
                    </a>
                </li>
            </PermissionsWrapper>

            <PermissionsWrapper
                user={user}
                roles={['authenticated_user']}>

                <li className="nav-item" id="plateformExplainVideos">
                    <a className="nav-link " onClick={() => { router.push(`/erklaervideos`) }}>
                        <i className="fa-regular  fa-circle-question cursor-pointer  tour_info_icon"
                           title="Erklärvideos für Kunden"></i>
                    </a>
                </li>
            </PermissionsWrapper>

            <li className="nav-item dropdown dropdown-language" id="userLangSelector">
                <a className="nav-link dropdown-toggle" id="dropdown-flag" href="#" data-bs-toggle="dropdown" data-bs-hover="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="flag-icon flag-icon-de"></i>
                    <span className="selected-language">{t.lang.german}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-flag">

                    <a className="dropdown-item" href="#" data-language="de">
                        <i className="flag-icon flag-icon-de"></i> {t.lang.german}
                    </a>
{/**
                    <a className="dropdown-item" href="#" data-language="en">
                        <i className="flag-icon flag-icon-us"></i> {t.lang.english}
                    </a>
 */}
                </div>
            </li>

            <li className="nav-item d-lg-block theme_preference " id="userThemePreferenceSelector">
                <a className="nav-link nav-link-style" onClick={() => changeTheme()} >
                    {theme == 'light-layout' ? <i className="fa-light fa-moon user-menu-theme-icon"></i> :  <i className="fa-regular fa-sun-bright user-menu-theme-icon"></i>}
                </a>
            </li>
            
            <li className="nav-item dropdown dropdown-notification me-25" id="alertsDropdown">
                <AlertsIcon />
            </li>

            <li className="nav-item dropdown dropdown-user" id="userDropdownMenu">
                <a className="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="user-nav d-sm-flex d-none">
                        <span className="user-name fw-bolder">{(user !== undefined) ? `${capitalizeFirstLetter(user.first_name)} ${capitalizeFirstLetter(user.last_name)}` : "LOGIN"}</span>
                        <span className="user-status">{(socket !== null && socket.connected === true) ? 'verfügbar' : 'offline'}</span>
                    </div>
                    <span className="avatar">
                        <img className="round" src={(user !== undefined && user.gravatar !== undefined) ? user.gravatar : 'https://www.gravatar.com/avatar/?d=retro'} alt="avatar" height="40" width="40" />
                        <span className={`avatar-status-${(socket !== null && socket.connected === true) ? 'online' : 'offline'}`}></span>
                    </span>
                </a>
                <div className="dropdown-menu dropdown-menu-end" id="dropdown-user" aria-labelledby="dropdown-user">
                    <a className="dropdown-item" onClick={() => { router.push(`/details/user/${(user !== undefined) ? user._id : ''}`) }}>
                        <i className="fa-solid fa-user me-1"></i> {t.user.profile}
                    </a>
                    <a className="dropdown-item" onClick={() => logout()}>
                        <i className="fa-solid fa-power-off me-1"></i> {t.user.logout}
                    </a>
                    <OptionalMenuEntries>
                        <PermissionsWrapper user={user} roles={['realm_admin']}>
                            <Fragment>
                                <a className="dropdown-item" onClick={() => showKey()}>
                                <i class="fa fa-key me-1" aria-hidden="true"></i> {t.user.token}
                                </a>
                            </Fragment>
                        </PermissionsWrapper>

                            {/* dont show if user has .own and only his own organization  */}
                            {((hasPermission({user: user, scopes: ['organization.switch.own']}) && user?.organizations?.length > 1) || hasPermission({user, scopes: ["organization.switch.any"]})) && 
                                <Fragment>
                                    <a className="dropdown-item" onClick={() => switchOrganization()}>
                                    <i class="fa fa-users me-1" aria-hidden="true"></i> Kunde <span className="text-muted">Ctl+Alt+o</span>
                                    </a>
                                </Fragment>
                            }

                        <PermissionsWrapper user={user} scopes={['force.logout.all']}>
                            <Fragment>
                                <hr/>
                                <a className="dropdown-item text-white bg-danger mt-2" onClick={() => forceLogoutAll()}>
                                <i class="fa-solid fa-skull-crossbones me-1" aria-hidden="true"></i> ALLE ausloggen
                                </a>
                            </Fragment>
                        </PermissionsWrapper>

                    </OptionalMenuEntries>
                </div>
            </li>
        </ul>
    )
}

export default UserMenu;