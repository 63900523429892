import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from "react-router-dom";
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {MgmtUsersContext} from "../../../../context/Users/UsersContext";
import {GroupsContext} from "../../../../context/Groups/Groups";
import {OrganizationContext} from "../../../../context/Organization/Organization";
import {LocationsContext} from "../../../../context/Locations/Locations";


import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import {useRouter} from "../../../../components/Shared/Router/Router";
import LocationGroupDetails from "../../../../components/Dashboard/group/LocationGroupDetails";
import UserGroupDetails from "../../../../components/Dashboard/group/UserGroupDetails";
import Swal from '@molline/sweetalert2'

import {NavLink} from "react-router-dom";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import PageTour from "../../../../components/Dashboard/tour/tour";
import TourSteps from "../../../../TourSteps.json";

import de from '../../../../components/Dashboard/tables/datatable.de.json'
const $ = require('jquery');
$.DataTable = require('datatables.net');


const GroupDetailsPage = (props) => {

    const router = useRouter()
    const { user } = useContext(AuthContext);
    const { group_id } = router.query
    const page_location = useLocation();

    const { 
        getGroups, 
        groups, 
        updateGroup, 
        getGroup, 
        group, 
        errorToast, 
        successToast, 
        doYouReallyWantToSave, 
        updatedGroup, 
        setUpdatedGroup,
        addGroupMember,
        resetMembers,
        removeMember,
        removeUser,
        addUser,
        resetUsers
    } = useContext(GroupsContext);

    const { getAllLocations} = useContext(LocationsContext);
    const { organization } = useContext(OrganizationContext);

    const groupIdChanged = useCompare(group_id);
    const organizationChanged = useCompare(organization);

    const groupsChanged = useCompare(groups);
    const groupChanged = useCompare(group);

    const [isEditing, setIsEditing] = useState(false);
    const [editForm, setEditForm] = useState({})
    const { mgmtUsers } = useContext(MgmtUsersContext)
    const [locations, setLocations] = useState([])

    const [tabActive, setTabActive] = useState('details')

    useEffect(() => {

        if(groupIdChanged === true) {
            getGroup(group_id)
            getLocs();

        }
     //   if(!!group.organization_id){
     //       if(page_location.pathname.search('details') > -1 && organizationChanged === true && group.organization_id !== organization._id){
     //           router.push('/admin/groups')
     //       }
     //   }
        
    }, [groups, groupsChanged, groupIdChanged, groupChanged, group, organizationChanged]);

    const getLocs = async () => {
      let unfilterData =  await getAllLocations({filter: false}).then().catch();
        setLocations(unfilterData);
    }

    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const capitalize = (str) => {
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    }

    const updateForm = (key, value, nestedKey) => {
        if (nestedKey) {
            const nested = editForm[nestedKey]
            nested[key] = value
            setEditForm({ ...editForm, [nestedKey]: nested })
        } else {
            setEditForm({ ...editForm, [key]: value })
        }
    }

    const updateCurrentGroup = async () => {
        const doSave = await doYouReallyWantToSave()
        if (!doSave) {
            return;
        }
        //console.log('groupEditForm', editForm)
        updateGroup(editForm).finally(setIsEditing(false))
    }

    const UI = () => {
        if(!group || Object.keys(group).length === 0) return (<Fragment></Fragment>)

        const RenderConditionalButtons = () => {
            if (isEditing === true) {
                return (
                    <div className="col-12">
                        <button  className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => { e.preventDefault(); updateCurrentGroup() }}>Speichern</button>
                        <button className="btn btn-muted mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                            e.preventDefault();
                            setIsEditing(false);
                        }}>Abbrechen</button>
                    </div>
                )
            } else {
                return (
                    <PermissionsWrapper
                        user={user}
                        scopes={['group.update.any', 'group.update.own']}
                    >
                        <div className="col-12">
                            <button id="groupDetailsUpdateBtn" className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                                e.preventDefault();
                                setEditForm(group)
                                setIsEditing(true);
                            }}>Anpassen</button>
                        </div>
                    </PermissionsWrapper>
                )
            }
        }

        return (
            <Fragment>
                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9">
                                <h2 className="content-header-title float-start mb-0">Gruppe ({group?.name || group?._id}) </h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Verwaltung</a></li>
                                        <li className="breadcrumb-item"><NavLink className="breadcrumb-navLink" to="/admin/groups"><a>Gruppen</a></NavLink></li>
                                        <li className="breadcrumb-item active">Details
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>

                <div className="content-body">
                    <section id="groupData">
                        <div className="row match-height">
                                
                            <div className="col-xl-2 col-md-4">
                                <div className="card text-center" id="groupDetailsMemberCounter">
                                    <div className="card-body">
                                        <div className="mx-auto my-1">
                                            <i class={`fa-solid fa-building fa-4x text-${(group !== null && group.users.length > 0) ? 'success' : 'primary'}`}></i>
                                        </div>
                                        <p className="text-muted mb-0 line-ellipsis">Nutzereinheiten</p>
                                        <h2 className="mb-0 ">{(group !== null) ? group.members.length : '0'}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4">
                                <div className="card text-center" id="groupDetailsUsersCounter">
                                    <div className="card-body">
                                        <div className="mx-auto my-1">
                                            <i className={`fa-solid fa-building-user fa-4x text-${(group !== null && group.users.length > 0) ? 'success' : 'primary'}`}></i>
                                        </div>
                                        <p className="text-muted mb-0 line-ellipsis">Benutzer</p>
                                        <h2 className="mb-0 ">{(group !== null) ? group.users.length : '0'}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section id="locationTabs">
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills mb-2">

                                <li className="nav-item" >
                                    <a className={`nav-link ${(tabActive === 'details') ? 'active' : ''}`} onClick={() => { setTabActive('details') }}>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <span className="fw-bold">Details</span>
                                    </a>
                                </li>

                                <li className="nav-item"  id="groupDetailMemberListItemTab">
                                    <a className={`nav-link ${(tabActive === 'relationships') ? 'active' : ''}`} onClick={() => { setTabActive('relationships') }}>
                                        <i className="fa-solid fa-link"></i>
                                        <span className="fw-bold">Nutzereinheiten</span>
                                    </a>
                                </li>

                                <li className="nav-item" id="groupDetailUserListItemTab">
                                    <a className={`nav-link ${(tabActive === 'users') ? 'active' : ''}`} onClick={() => { setTabActive('users') }}>
                                        <i className="fa-solid fa-building-user"></i>
                                        <span className="fw-bold">Benutzer</span>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </section>

                <section id="tabpanels">
                    <div className="row">
                        <div className="col-12">
                            <div class="tab-content pt-1">

                                <div class={`tab-pane ${(tabActive === 'details') ? 'active' : ''}`} id="details" role="tabpanel" aria-labelledby="home-tab-fill">
                                    <div className="card" id="groupDetailsOverview">
                                        <div className="card-header border-bottom">
                                            <h4 className="card-title">Details der Gruppe <PageTour steps={TourSteps.Groups.Details.overView}  isButton={false} /></h4>
                                        </div>
                                        <div className="card-body py-2 my-25">
                                             <form id="group-detail-frm">
                                                <div className="row">
                                                    <div className="mb-1 col-md-12 col-xl-12">
                                                        <label className="form-label" for="groupTypeSelect2">Gruppentype*</label>
                                                        <select class="form-control" id="groupTypeSelect2" onChange={(e) => { updateForm('type', e.target.value) }} disabled={!isEditing}>
                                                            <option value="locations" selected={ (!!group.type && group.type === 'locations')? true:false }>Liegenschaft</option>
                                                            <option value="calculate" selected={ (!!group.type && group.type === 'calculation')? true:false }>Berechnung</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-1 col-md-12 col-xl-12">
                                                        <label className="form-label">Gruppenname*</label>
                                                        <input type="text" className="form-control" value={ isEditing ? editForm?.name : capitalize(group.name) } disabled={!isEditing}  required
                                                               onChange={(e) => { updateForm('name', e.target.value) }}
                                                                />
                                                    </div>
                                                    <div className="mb-1 col-md-12 col-xl-12">
                                                        <label className="form-label">Tags</label>
                                                        <input type="text" className="form-control" disabled={!isEditing}

                                                               value={ isEditing ? editForm?.tags : group.tags }

                                                               onChange={(e) => { updateForm('tags', e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='row'>
                                                {RenderConditionalButtons()}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class={`tab-pane ${(tabActive === 'relationships') ? 'active' : ''}`} id="relationships" role="tabpanel" aria-labelledby="home-tab-fill">
                                    <div className="card">
                                        <div className="card-header border-bottom">
                                            <h4 className="card-title">Nutzer-Mieteinheiten  in der Gruppe <PageTour steps={TourSteps.Groups.Details.member}  isButton={false} /></h4>
                                            <div className="heading-elements">
                                                <div className="heading-elements">
                                                    <button id="groupDetailsAddMemberbtn" className='btn btn-success me-1' onClick={() => addGroupMember({locations}) }><i className="fa-solid fa-plus"></i></button>
                                                    <button id="groupDetailsResetMembersBtn" className='btn btn-danger ' onClick={() => resetMembers() }><i className="fa-solid fa-trash"></i> Nutzereinheiten zurücksetzen</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body py-2 my-25">
                                            <LocationGroupDetails
                                                group={group}
                                                removeMember={removeMember}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class={`tab-pane ${(tabActive === 'users') ? 'active' : ''}`} id="users" role="tabpanel" aria-labelledby="home-tab-fill">
                                    <div className="card">
                                        <div className="card-header border-bottom">
                                            <h4 className="card-title">Beutzer der Gruppe <PageTour steps={TourSteps.Groups.Details.user}  isButton={false} /> </h4>
                                            <div className="heading-elements">
                                                <button id="groupDetailsAddUserbtn" className='btn btn-success me-1' onClick={() => addUser(mgmtUsers)}><i className="fa-solid fa-plus"></i></button>
                                                <button id="groupDetailsResetUsersBtn" className='btn btn-danger ' onClick={() => resetUsers() }><i className="fa-solid fa-trash"></i> Benutzer zurücksetzen</button>
                                            </div>
                                        </div>
                                        <div className="card-body py-2 my-25">
                                            <UserGroupDetails
                                                group={group}
                                                removeUser={removeUser}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
         

    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )




}


GroupDetailsPage.requiredRole =['realm_manager', 'realm_admin'];
GroupDetailsPage.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default GroupDetailsPage;