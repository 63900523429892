import React, {Fragment, useEffect, useContext, useState } from 'react';
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { CSVLink, CSVDownload } from "react-csv";
import { PermissionsWrapper } from '../../reactPermissionsWrapper';
import { AuthContext } from '../../../../context/Auth/AuthProvider';

const CountMbusSensorsWidget = (props) => {

    const { sensors, sensorsChanged, sanitizedSensor } = useContext(SensorsContext);
    const {user} = useContext(AuthContext)

    useEffect(  () => {
            
    }, [sensors, sensorsChanged ]);


    const UI = () => {
        let sensorArray = [];
        let totalMbusCount = 0;
        if(!!sensors && sensors.length > 0){
            sensorArray = sensors.filter(sensor => {
                if(sensor.attributes.includes('mbus')) return sanitizedSensor(sensor)
            })

            let date = new Date()
            let mbus = sensors.filter(sensor => sensor.attributes.includes('mbus'));

            for(let sensor of mbus){
                let sDate = new Date(sensor.state.date)
                let hours = Math.abs(date - sDate) / 36e5;
                if(hours < 360){
                    totalMbusCount ++
                }
            }
        }

        if(sensorArray.length > 0){
            return (
                <div className="col-xl-2 col-md-4" id="widgetMBusSensorsCounter">
                    <div className="card text-center " title="Anzahl der MBUS-Zähler">
                        <PermissionsWrapper
                            user={user}
                            roles={['realm_admin', 'realm_manager']}
                        >
                            <CSVLink
                                data={sensorArray}
                                filename={"mbus-sensor-report.csv"}
                            >
                                <span className="badge rounded-pill bg-info fa-fade badge-up"><i class="fa-solid fa-download"></i></span>
                            </CSVLink>
                        </PermissionsWrapper>
                        <div className="card-body align-self-center">
                            <div className="mx-auto my-1" style={{ display: "inline-flex", alignItems: "center" }}>
                                <i className={`fa-solid fa-diagram-nested fa-4x text-success`}></i>
                            </div>
                            <p className="mb-0 text-muted">MBUS-Zähler empfangen</p>
                            <h2 className="mb-0 line-ellipsis ">{`${totalMbusCount}/${sensorArray.length}`}</h2>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <Fragment></Fragment>
        )
        
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountMbusSensorsWidget;