import React, {Fragment, useState, useEffect, useContext, useRef} from 'react';
import {useRouter} from "../../Shared/Router/Router";
import Swal from "@molline/sweetalert2";
import {PermissionsWrapper, hasPermission, MembershipWrapper, hasMembership} from "../reactPermissionsWrapper";
import {AuthContext} from "../../../context/Auth/AuthProvider";
import {LocationsContext} from "../../../context/Locations/Locations";
import {MgmtUsersContext} from "../../../context/Users/UsersContext";
import {OrganizationContext} from "../../../context/Organization/Organization";
import uniqId from "uniqid";
import ReportService from '../../../utils/reportsService/reportService'
import {SensorsContext} from "../../../context/Devices/Sensors";
import { CSVLink, CSVDownload } from "react-csv";

import ReportUviReportPackage from '../widgets/locations/reportUviReportPackage';
import ReportUviReportWidget from '../widgets/locations/reportUviReport';
import ReportSensorMeasurementsWidget from '../widgets/sensors/reportSensorMeasurements';
import ReportYearEndSensorWidget from '../widgets/sensors/reportYearEndSensor';
import ReportSensorsChangedWidget from '../widgets/sensors/reportSensorChanged';

import ReportSensorMeasurementsErasWidget from '../widgets/sensors/reportSensorMeasurementsEras';
import UviReportWidget from '../widgets/sensors/uviReportWidget';
import ReportDeadSensors from '../widgets/sensors/reportDeadSensors';
import ReportDeadGateways from '../widgets/gateways/reportDeadGateways';

import capitalizeUtils from "../../../utils/capitalizeUtils";


/*******
* Note: imported "Offcanvas ReportProvider" in LayoutHandler.js so that it could access in everyPage on Berichte click.
*******/

const ReportsProvider = (props) => {

    const { user } = useContext(AuthContext);
    const { locations, location, locationIcon, setLocation } = useContext(LocationsContext);
    const { sensors } = useContext(SensorsContext);
    const { organization } = useContext(OrganizationContext);
    const router = useRouter()

    const [isfrequentReports, setIsFrequentReports] = useState(true);

    const locationsChanged = useCompare(locations);
    const locationChanged = useCompare(location);

    const sensorsChanged = useCompare(sensors)

    useEffect(() => {
        if (locationsChanged === true && locations.length > 0 && (location === undefined || JSON.stringify(location) === '{}')) {
            setLocation(locations[0]);
        }

        if (locationChanged === true  ) {
           //console.log('LOCATION CHANGED', location)
        }


    }, [user, sensors, sensorsChanged, location, locationsChanged, locationChanged ])


    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    
    const reportHelpHandler = (title, descriptionText) => {
        Swal.fire({
            icon: "info",
            title: title,
            allowOutsideClick: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            confirmButtonText: 'Ok',
            text: descriptionText,
        })
    }

    const returnFrequentReports = () => {
        let frequent = [];
      
        frequent.push(<ReportUviReportPackage />)
        frequent.push(<ReportUviReportWidget />);
        frequent.push(<ReportYearEndSensorWidget />)
      
        return frequent;
    }



    const UI = () => {
        if(Object.keys(location).length === 0  || !user || locations.length === 0) return <Fragment></Fragment>
        let orderedLocs = [...locations]
        orderedLocs.sort(function (a, b) {
            const bLocString = `${b.address.street} ${a.address.house_number} ${b.address.street1 === "" ? "" : b.address.street1}`
            const aLocString = `${a.address.street} ${b.address.house_number} ${a.address.street1 === "" ? "" : a.address.street1}`
            return aLocString.localeCompare(bLocString, 'de', {
                numeric: true
            })
        });

        return (
            <Fragment>
                <div className="reports-offcanvas-start">

                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="reportsOffcanvas"
                         aria-labelledby="reportsOffcanvasLabel">
                        <div className="offcanvas-header">
                            <h5 id="reportsOffcanvasLabel" className="offcanvas-title"></h5>

                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body my-auto align-items-start">
                            {
                                isfrequentReports === true ?
                                    <div>
                                        <h2 className="text-center">Häufige Berichte</h2>
                                        <hr/>
                                        <div className="row match-height">
                                            {returnFrequentReports()}
                                        </div>
                                    </div>

                                    :
                                    <></>
                            }
                            <h2 className="text-center">Berichte</h2>
                            <hr/>
                            <div className="card-body">
                                <div className="mb-2">
                                    <select className="form-select" name="type" onChange={ (e) => {let loc = locations.filter(loc => loc._id === e.currentTarget.value)[0]; setLocation(loc)} }>
                                        {orderedLocs.map(loc => (
                                            <option key={uniqId()} selected={location._id === loc._id} value={loc._id}>
                                                <span dangerouslySetInnerHTML={{ __html: locationIcon({ location: loc, size: 2 }) }}></span>
                                                {getLocationAddress(loc)}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="accordion accordion-border" id="accordionBorder">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingBorderOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#locationsReportsTab" aria-expanded="false" aria-controls="accordionBorderOne">Nutzereinheiten</button>
                                        </h2>
                                        <div id="locationsReportsTab" className="accordion-collapse collapse" aria-labelledby="headingBorderOne" data-bs-parent="#accordionBorder">
                                            <div className="accordion-body">
                                                <div className='mt-2'>
                                                    <div className="row match-height">

                                                        <ReportUviReportPackage />
                                                        <ReportUviReportWidget />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingBorderTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sensorsReportsTab"
                                                    aria-expanded="false" aria-controls="accordionBorderTwo">Zähler
                                            </button>
                                        </h2>
                                        <div id="sensorsReportsTab" className="accordion-collapse collapse" aria-labelledby="headingBorderTwo" data-bs-parent="#accordionBorder">
                                            <div className="accordion-body">
                                                <div className='mt-2'>
                                                    <div className="row match-height">

                                                        <ReportSensorMeasurementsWidget />
                                                        <ReportYearEndSensorWidget />
                                                        <ReportSensorsChangedWidget />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <PermissionsWrapper
                                        user={user}
                                        scopes={['organization.view.any']}
                                    >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingBorderThree">
                                                <button className="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#firmSpecificTab"
                                                        aria-expanded="false" aria-controls="headingBorderThree" style={{backgroundColor:"lightGreen"}}>PRIVATE
                                                </button>
                                            </h2>
                                            <div id="firmSpecificTab" className="accordion-collapse collapse" aria-labelledby="headingBorderThree" data-bs-parent="#accordionBorder">
                                                <div className="accordion-body">
                                                    <div className='mt-2'>
                                                        <div className="row match-height">

                                                            <ReportSensorMeasurementsErasWidget />
                                                            <UviReportWidget />
                                                            <ReportDeadSensors />
                                                            <ReportDeadGateways />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </PermissionsWrapper>






                                </div>
                            </div>
                            {/*<button type="button" className="btn btn-primary mb-1 d-grid w-100">Generate Report</button>*/}
                            <button type="button" className="btn btn-outline-secondary d-grid w-100" id="report_tab_close"
                                    data-bs-dismiss="offcanvas">Schließen
                            </button>
                        </div>
                    </div>
                </div>


            </Fragment>
        );

    }



    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}



/* Location selection Dropdown (used current one if needed later change it) */
const getLocationAddress = (loc) => {
    if (!loc) return;
    return `${capitalizeUtils.capitalize(loc?.address?.street)} ${loc?.address?.house_number}, ${(loc?.address?.street1 !== '') ? `Mieteinheit ${loc?.address?.street1}, ` : ''}${loc?.address?.post_code} ${capitalizeUtils.capitalize(loc?.address?.city)}, ${loc?.address?.country.toLocaleUpperCase()}`
}


export default ReportsProvider;