import React, {Fragment, useState, useEffect, useContext, useRef, } from 'react';
import { MgmtUsersContext } from '../../../../context/Users/UsersContext';
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import Swal from '@molline/sweetalert2'

import { useRouter, } from "../../../../components/Shared/Router/Router"
import uniqId from 'uniqid'

import {OrganizationContext} from '../../../../context/Organization/Organization';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faTrash, faPlus, faLockOpen,  faPeopleGroup, faEnvelope, faLock, faXmark, faChain, faWrench, faClose } from '@fortawesome/free-solid-svg-icons'
/**
 * Fixes error with "window not available" in reat-apexcharts
 */

import OrgCookieCrumb from '../../../../components/Dashboard/orgCookieCrumb';
import { LocationsContext } from '../../../../context/Locations/Locations';

import {NavLink} from "react-router-dom";
import de from "../../../../components/Dashboard/tables/datatable.de.json";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import {faSquareCheck} from "@fortawesome/free-solid-svg-icons/faSquareCheck";
import {faBrakeWarning} from "@fortawesome/pro-solid-svg-icons";
import {hasPermission, PermissionsWrapper} from "../../../../components/Dashboard/reactPermissionsWrapper";
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";
import AxiosClient from "../../../../utils/axios_client";
import Cookie from "js-cookie";
import CryptoJS from "crypto-js";


const $ = require('jquery');
$.DataTable = require('datatables.net');

const capitalize = (str) => {
    if (!str || typeof str !== 'string') return str;
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

const formatDate = (value) => {
    if (value === undefined) {
        return;
    }
    let date = new Date(value)
    var options = {year: 'numeric', month: 'long', day: 'numeric',};
    return date.toLocaleDateString("de-DE", options);
}

const toast = (msg, isError) => {
    return Swal.fire({
        toast: true,
        position: 'bottom-end',
        timer: 3000,
        timerProgressBar: true,
        allowOutsideClick: true,
        html: msg,
        showConfirmButton: false,
        customClass: {
            popup: isError?"bg-danger text-white font-weight-bold": "bg-success text-white font-weight-bold"
        }
    })
}

const ProcessingBlocker = () => {
    return (
        <div className="bg-secondary" style={{borderRadius:"0.428rem", width:"100%", height:"100%", top:"0", left:"0", position:"absolute", "--bs-bg-opacity": ".55", zIndex:"999" }}>
            <div style={{display:"flex", alignItems:"center", flexDirection:"row", height:"100%", width:"100%"}}>
                <div style={{display:"flex", alignItems:"center", flexDirection:"column", flex:"1 1 auto"}}>
                    <h4 className="text-center text-white"><i className="fa-solid fa-cog fa-spin"></i> WIRD AKTUALISIERT ...</h4>
                </div>
            </div>
        </div>
    )
}

const OptionalContent = ({children}) => {
    let childs = React.Children.toArray(children);
    if(!childs || childs?.length <= 0) {
        return (<Fragment></Fragment>)
    }
    return (
        <div className="card">
            <div className="card-body py-2 my-25">
                {children}
            </div>
        </div>
    )
}

const UserDetails = (props) => {

    const router = useRouter()
    const { user_id } = router.query
    const { user } = useContext(AuthContext);
    const { organization, organizations } = useContext(OrganizationContext);
    const [mgmtUser, setMgmtUser] = useState({}) // This user profile

    const {
        updateUser,
        roles,
        scopes,
        getUser,
        sendVerificationMail,
        deactivateUser,
        changeRoles,
        changeUserPassword,
        updateUserAccessOrgs,
        resendInvite
    } = useContext(MgmtUsersContext);
    
    const [tabActive, setTabActive] = useState('account')
    const [deactivateActive, setDeactivateActive] = useState(false)
    const [isEditingUser, setIsEditingUser] = useState(false)
    const { locations } = useContext(LocationsContext);
    const [postalAddress, setPostalAddress] = useState(null);
    const [newAddress, setNewAddress] = useState(null);

    const [isUpdating, setIsUpdating] = useState(false);

    const passwordForm = useRef(null);
    const password = useRef(null);
    const newPassword = useRef(null);
    const confirmPassword = useRef(null);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState(null);

    const mgmtUserChanged = useCompare(mgmtUser);
    const userChanged = useCompare(user);
    const organizationChanged = useCompare(organization);
    const userIdChanged = useCompare(user_id);

    const [scopesFilter, setScopesFilter] = useState(null);
    const [editForm, setEditForm] = useState({})

    const [isLoading, setIsLoading] = useState(false);
    const [isOrgsEdit, setIsOrgsEdit] = useState(false);

    useEffect(() => {
        
        if ((mgmtUser?.active === false)) {
            router.push('/admin/users');
        }

        if (!!userIdChanged && !!organization) {
            getMgmtUser();
        }

        if(mgmtUserChanged === true) {
            setNewAddress(null);
            if(mgmtUser.addresses && mgmtUser.addresses[0]) {
                const _addressString = getPostalAddressString(mgmtUser.addresses[0]);
                setPostalAddress(_addressString);
            }
        }

    }, [userIdChanged, mgmtUser,organizationChanged,organization,userChanged,mgmtUserChanged,roles, scopes, user_id, organizations, user, router]);
    
    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 4000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 4000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger"
            }
        })
    }

    /**
     * Pull the User from the DB
     */
    const getMgmtUser = () => {
        setIsUpdating(true);
        return new Promise(async (resolve, reject)=> {
            getUser(user_id)
            .then( async fetchedUser => {
                setMgmtUser(fetchedUser)
            })
            .catch(err => {

            })
            .finally(() => setIsUpdating(false))
        })
    }
    /**
     * Update user
     */
    const doUpdateUser = async (e) => {
        e.preventDefault();
        if(!mgmtUser || !mgmtUser._id) {
            setIsEditingUser(false)
            getMgmtUser();
            return;
        }

        setIsUpdating(true);

        let updatePayload = editForm
        let validMails = mgmtUser.emails //non-empty, unique emails only
            .filter((em, i, a)=>em.email&&em.email.trim()!=""
                && (a.findIndex((e)=>e.email === em.email) === i)
            )
        if(validMails.length > 1) {
            updatePayload.emails = validMails;
        }
        if(!updatePayload.phone && mgmtUser.phones && mgmtUser.phones[0]) {
            updatePayload.phone = mgmtUser.phones[0].number;
        }
        if(updatePayload.emails) {
            let newMails = updatePayload.emails.filter((em)=>em.new);
            if(newMails.length > 0) {
                let answer = await Swal.fire({
                    title:"Achtung E-Mails werden versendet!",
                    iconHtml:<i className="fa-solid fa-at"/>,
                    confirmButtonText:"Ja, abschicken.",
                    cancelButtonText:"Abbrechen",
                    showCancelButton: true,
                    customClass: {
                        icon:"border-0"
                    },
                    html:<Fragment>
                        { newMails && newMails.length > 0 &&
                            <Fragment>
                                <strong>Achtung! Sie haben neue Emails hinzugefügt.</strong> Diese Emails erhalten eine Verifizierungs-Email, bitte stellen Sie sicher dass die Email-Addressen auch wirklich Ihnen gehört:<br/>
                                <br/>
                                {newMails.map((em)=>(<Fragment><span style={{fontSize:"1.2em"}} className="mb-1 badge rounded-pill bg-secondary me-25">{em.email}</span><br/></Fragment>))}
                            </Fragment>
                        }

                    </Fragment>
                })
                if(!answer.isConfirmed) {
                    setIsUpdating(false);
                    return;
                }
            }
        }
        updateUser({user_id: mgmtUser._id, payload:updatePayload})
            .then(async(response)=>{
                console.log("successfully updated user", user)
                if(!!response.createdMails && Object.keys(response.createdMails).length > 0) {
                    let msg = "Folgende Mails wurden angelegt:<br/><br>"
                    for(let [email, message] of Object.entries(response.createdMails)) {
                        msg += `${email}<br/>`
                    }
                    toast(msg);
                }
                getMgmtUser();
            })
            .catch(async (err) => {
                if(typeof err === "object" && !!err.response && !!err.response.data) {
                    let data = err.response.data;
                    if(data) {
                        let promises = [];
                        if(!!data.rejectedMails && Object.keys(data.rejectedMails.length > 0)) {
                            let msg = "Fehler bei folgenden Mails:<br/><br/>";
                            for(let [email, message] of Object.entries(data.rejectedMails)) {
                                msg += `${email} - ${message}<br/>`
                            }
                            promises.push(toast(msg, true));
                        }
                        if(!!data.createdMails && Object.keys(data.createdMails).length > 0) {
                            let msg = "Folgende Mails wurden angelegt:<br/><br>"
                            for(let [email, message] of Object.entries(data.createdMails)) {
                                msg += `${email}<br/>`
                            }
                            Promise.all(promises).then(()=>toast(msg));
                        }
                    }
                } else {
                    console.error(err);
                }
            }).finally(()=>{
            setIsUpdating(false);
            setIsEditingUser(false)
        });

    }

    const doVerifyPasswords = (e) => {
        e.preventDefault();
        let passwd = newPassword.current.value;
        console.log("passwd")
        let capLetters = passwd.match(/[A-Z]/g) // min 2
        let smlLetters = passwd.match(/[a-z]/g)
        let numbers = passwd.match(/[0-9]/g)
        let punc = passwd.match(/[!#$%€&(*+<>?@[\]^_}]/g)
        let errors = [];

        if(passwd.length < 12) errors.push('Dass Passwort muss mindestes 12 Zeichen lang sein.');
        if(!capLetters || capLetters.length < 2) errors.push('Das Passwort muss mindestens 2 GROSSBUCHSTABEN haben.');
        if(!smlLetters || smlLetters.length < 2) errors.push('Das Passwort muss mindestes 2 Kleinbuchstaben haben');
        if(!numbers || numbers.length < 2) errors.push('Das Passwort muss mindestens 2 Nummern haben.');
        if(!punc || punc.length < 2) errors.push('Das Passwort muss mindestens 2 Sonderzeichen besitzen');

        if(errors.length>0) {
            newPassword.current.classList.add("border-danger");
            newPassword.current.classList.add("border-2");
            confirmPassword.current.classList.add("border-danger")
            confirmPassword.current.classList.add("border-2")
            setPasswordErrors(errors);
        } else {
            newPassword.current.classList.remove("border-danger");
            newPassword.current.classList.remove("border-2");
            confirmPassword.current.classList.remove("border-danger")
            confirmPassword.current.classList.remove("border-2")
            setPasswordErrors([])
            passwordForm.current.classList.add('was-validated');
        }
    }

    const doChangePassword = (e) => {
        e.preventDefault();
        let passwordCurrent = document.getElementById('accountCurPassword').value;
        let passwordNew = document.getElementById('accountNewPassword').value;
        changeUserPassword({ user_id: mgmtUser._id, passwordCurrent, passwordNew })
        .then( res => {
            toast('Success!!', false)
            document.getElementById('accountCurPassword').value = '';
            document.getElementById('accountNewPassword').value = '';
        })
        .catch(err => {
            toast(err, true)
            document.getElementById('accountCurPassword').value = '';
        })
    }

    /**
     * Delete User
     */
    const doDeactivateUser = (e) => {
        e.preventDefault();
        deactivateUser({ user_id: mgmtUser._id }).then((_delUser) => {
            console.log("deactived user:", _delUser)
            setMgmtUser(_delUser);
            router.push('/admin/users')
        });
    }

    const toggleDropdown = (e) => {
        document.getElementById(e.target.dataset.toggle).classList.toggle('show');
    }

    const toggleDeactivateBtn = (e) => {
        console.log(e.target.checked)
        e.preventDefault()
        if (e.target.checked === true) {
            document.getElementById('btnDeactivateAccount').disabled = false
        } else {
            document.getElementById('btnDeactivateAccount').disabled = true
        }
    }

    const addUserEmail = (e) => {
        e.preventDefault();
        if(mgmtUser.emails.length >= 5) return; //allow no more then 5 at once
    }

    const removeUserEmail = (e, i) => {
        e.preventDefault();
        if(!i) return;
    }

    const editUser = (e) => {
        e.preventDefault()
        if(mgmtUser?.phones && mgmtUser.phones.length !== 0) mgmtUser.phone = mgmtUser.phones[0].number
        setIsEditingUser(true);
        setEditForm(mgmtUser)
    }

    const cancelUserEdit = (e) => {
        e.preventDefault()
        setPasswordErrors([]);
        getMgmtUser();
        setPostalAddress(null);
        setNewAddress(null);
        setIsEditingUser(false);
        
        //null value all inputs in form
    }

    const updateForm = (key, value, nestedKey) => {
        if (nestedKey) {
            const nested = editForm[nestedKey]
            nested[key] = value
            setEditForm({ ...editForm, [nestedKey]: nested })
        } else {
            console.log(key, value);
            setEditForm({ ...editForm, [key]: value })
        }
    }

    const initDT = ({ id, data, columns = [], options = {}, force = true }) => {
        if (!data || !force && data.length <= 0 || $.fn.DataTable.isDataTable(id) || !force && !$(id).is(":visible"))
            return;
        //console.log("initializing ...")
        let table = $(id).DataTable({
            "language": de,
            "processing": true,
            "data": data,
            "rowId": "_id",
            "order": [[0, "desc"]],
            "pageLength": 25,
            "columns": columns,
            "drawCallback": function (settings) {
                $('#tblSelectLocations tbody tr').off('mouseenter').on('mouseenter', function () {
                    $(this).css('cursor', 'pointer');
                    $(this).css('background-color', '#f3f2f7');
                });

                $('#tblSelectLocations tbody tr').off('mouseleave').on('mouseleave', function () {
                    $(this).css('background-color', '#fff');
                });
            },
            ...options
        });
    }

    const findLocationModal = async () => {
        return new Promise((resolve, reject) => {
            const changePopup = Swal.fire({
                width: "98%",
                title: `Addresse aus Liegenschaftsdaten suchen`,
                allowOutsideClick: true,
                backdrop: true,
                allowEscapeKey: true,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Abbrechen',
                html:
                    `<table id="tblSelectLocations" class="table mb-0 row-border hover order-column" role="grid" width="100%">
                    <thead>
                        <tr>
                            <th>Alias</th>
                            <th>Straße</th>
                            <th>Hausnr.</th>
                            <th>Wohnung</th>
                            <th>Stadt</th>
                            <th>PLZ</th>
                            <th>Land</th>
                            <th></th>
                            <th>ID</th>
                        </tr>
                    </thead>
                </table>
                `,
                willOpen: () => {
                    initDT({
                        id: '#tblSelectLocations',
                        data: locations,
                        columns: [
                            {
                                "data": "nickname",
                                render: (data) => capitalize(data)
                            },
                            {
                                "data": "address.street",
                                render: (data) => capitalize(data)
                            },
                            { "data": "address.house_number" },
                            { "data": "address.street1" },
                            {
                                "data": "address.city",
                                render: (data) => capitalize(data)
                            },
                            { "data": "address.post_code" },
                            {
                                "data": "address.country",
                                render: (data) => data.toLocaleUpperCase()
                            },
                            {
                                data: "null", render: function (data, type, row, meta) {
                                    return `<div style="text-align:right"><button class="btn btn-primary btn-small" id="location-select-btn" data-location_id=${row._id}>Auswählen</button></div>`
                                }
                            },
                            { "data": "_id" },
                        ],
                        options: {
                            rowId: "_id",
                            columnDefs: [{ "defaultContent": "-", "targets": "_all" }],
                            drawCallback: function (settings) { //to make it work on pagination
                                $('#tblSelectLocations tbody tr').off('mouseenter').on('mouseenter', function () {
                                    $(this).css('cursor', 'pointer');
                                    $(this).css('background-color', '#f3f2f7');
                                });

                                $('#tblSelectLocations tbody tr').off('mouseleave').on('mouseleave', function () {
                                    $(this).css('background-color', '#fff');
                                });

                                $('#tblSelectLocations tbody tr').off('click').on('click', function () {
                                    const button = $(this).find('button').first();
                                    if (button && button.get(0)) {
                                        resolve(button.get(0).dataset);
                                        changePopup.close();
                                    }
                                });
                            }
                        },
                        force: true
                    })

                }
            })

        });
    }

    const getPostalAddressString = (address) => {
        if(!address) return null;
        let addressStr = `${capitalize(address.street)} ${address.house_number},`
        if (address.street1) {
            addressStr += ` Wohnung ${capitalize(address.street1)},`
        }
        addressStr += ` ${address.post_code} ${capitalize(address.city)}`
        return addressStr;
    }

    const AddressField = () => {
        const newAddress = {
            street: useRef(),
            house_number: useRef(),
            post_code: useRef(),
            city: useRef(),
            country: useRef(),
            province: useRef(),
            street1: useRef()
        }

        const onChangeAddress = () => {
            if (mgmtUser === undefined || !mgmtUser.addresses) return;
            const firstAddress = mgmtUser.addresses[0];
            const conditionalGetValue = (field) => {
                if (firstAddress && firstAddress[field]) return firstAddress[field];
            }
            Swal.fire({
                iconHtml: '<i class="fa-solid fa-envelope"/>',
                title: 'Postale Addresse ändern',
                customClass: {
                    icon: 'border-0'
                },
                confirmButtonText: "Ändern",
                cancelButtonText: "Abbrechen",
                showCancelButton: true,
                html: <Fragment>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input type="text" className="form-control usrAccount mb-1 me-1" id="addressStreet" name="street" defaultValue={conditionalGetValue('street')} placeholder="Straße" ref={newAddress.street} />
                        <input type="text" className="form-control usrAccount mb-1" id="addressHouseNr" name="house_number" placeholder="Nr." defaultValue={conditionalGetValue('house_number')}  ref={newAddress.house_number} style={{ width: "25%" }} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input type="text" className="form-control usrAccount mb-1 me-1" id="addressPostcode" name="postcode" placeholder="PLZ" defaultValue={conditionalGetValue('post_code')}  ref={newAddress.post_code} style={{ width: "50%" }} />
                        <input type="text" className="form-control usrAccount mb-1" id="addressTown" name="town" placeholder="Stadt" defaultValue={conditionalGetValue('city')} ref={newAddress.city} />
                    </div>
                    <input type="text" className="form-control usrAccount mb-1" id="addressAppartment" name="appartment" placeholder="Wohnung" defaultValue={conditionalGetValue('street1')} ref={newAddress.street1} />
                    <input type="text" className="form-control usrAccount mb-1" id="addressCountry" name="country" placeholder="Land" defaultValue={conditionalGetValue('country') || "Deutschland"} ref={newAddress.country} />
                    <input type="text" className="form-control usrAccount mb-1" id="addressState" name="state" placeholder="Bundesland" defaultValue={conditionalGetValue('province')} ref={newAddress.province} />
                </Fragment>
            }).then((response) => {
                if (!response.isConfirmed) return;
                let address = {};
                for (const [key, value] of Object.entries(newAddress)) {
                    address[key] = value.current.value;
                }
                // console.log("New address is", address);
                if(mgmtUser && mgmtUser.addresses) {
                    mgmtUser.addresses[0] = address;
                    setMgmtUser(mgmtUser);
                    const _addressString = getPostalAddressString(mgmtUser.addresses[0]);
                    setPostalAddress(_addressString);
                }

            })
        }

        return (
            <Fragment>
                <label className="form-label" htmlFor="accountAddress">Postale Addresse</label>
                <div className="input-group" onClick={/*isEditingUser ? () => { onChangeAddress(); } :*/ () => { }}>
                    <span className="input-group-text"><FontAwesomeIcon icon={faEnvelope} /></span>
                    <input type="text" className="form-control usrAccount" id="accountAddress" name="address" placeholder="Addresse" value={postalAddress} disabled={true/*!isEditingUser*/} />
                </div>
            </Fragment>
        )
    }

    const getUserVerificationInfo = () => {
        if(mgmtUser?.emails &&  mgmtUser.emails?.length > 0 && mgmtUser?.emails[0]?.verified_at?.length > 1) {
            return (
                <Fragment>
                    <div className="col-12 col-sm-6 mb-1">
                        <label className="form-label" htmlFor="accountEmailVerifiedAt">Verifizierungs-Datum</label>
                        <input type="text" className="form-control usrAccount" id="accountEmailVerifiedAt" name="email_verify" placeholder="Email Verified_at" defaultValue={(mgmtUser !== undefined && mgmtUser.emails && mgmtUser.emails[0]) ? formatDate(mgmtUser.emails[0].verified_at) : ''} disabled />
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className="col-12 col-sm-6 mb-1">
                        <label className="form-label" htmlFor="accountEmailCreatedDate">Erstellungs-Datum</label>
                        <input type="text" className="form-control usrAccount" id="accountEmailCreatedDate" name="email_created_at" placeholder="Email Datum" defaultValue={(mgmtUser !== undefined && mgmtUser.emails && mgmtUser.emails[0]) ? formatDate(mgmtUser.emails[0].created_at) : ''} disabled />
                    </div>
                </Fragment>
            )
        }

    }

    const generatePassword = ({e, passwordLength = 14}) => {
        e.preventDefault();
        let password = '', punc, forbidden, numbers, char_upper, char_lower;
        if(passwordLength < 12) passwordLength = 12;
        const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789%&$?#€@';
        const charactersLength = characters.length;
        do{
            password = ''; punc = null; forbidden= null; numbers= null; char_upper= null; char_lower= null; let len = passwordLength;
            while(len--){
                password += characters[Math.floor(Math.random() * charactersLength)]
                //console.log(password)
            }
            punc = (password.match(/[%&$?#€@]/g) || []).length;
            forbidden = (password.match(/[{'}=(`)/\\]/g) || []).length;
            numbers = (password.match(/[0-9]/g) || []).length;
            char_upper = (password.match(/[A-Z]/g) || []).length;
            char_lower = (password.match(/[a-z]/g) || []).length;
        }while(
            punc < 2 || 
            forbidden > 0 || 
            numbers < 2 || 
            char_upper < 2 || 
            char_lower < 2 
        )
        console.log('PASSWORD', password)
        let pass = password.trim();
        let target = document.getElementById('accountNewPassword');
        if(target) target.value = pass
        return  pass;
    }

    const addUserOrgs =  () => {

        let filteredOrgs = organizations
        if( !hasPermission({user, scopes:['organization.switch.any', 'organization.switch.own']}) ) return;

        // Filter already added organisations (user.organisations arr) & also exclude user primary org i.e user.organization_id
        filteredOrgs = filteredOrgs.filter(org => {
            return !mgmtUser.organizations.includes(org._id) && org._id !== mgmtUser.organization_id;
        });

        Swal.fire({
            width: "80%",
            title: `Kundenzugänge wählen`,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
            confirmButtonText: 'Wählen',
            cancelButtonText: 'Abbrechen',
            html: `<input class="hidden" id="orgID" type="text">
                    <table id="tblPickerOrganizations" class="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                            <tr>
                                <th >ID</th>
                                <th >Kunde</th>
                                <th >Straße</th>
                                <th >Stadt</th>
                            </tr>
                        </thead>
                    </table>`,
            willOpen: () => {
                if (organizations.length > 0) {
                    let table;
                    if ($.fn.dataTable.isDataTable('#tblPickerOrganizations')) {
                        table = $('#tblPickerOrganizations').DataTable();
                    } else {
                        table = $('#tblPickerOrganizations').DataTable({
                            "language": de,
                            paging: true,
                            "pagingType": "full",
                            processing: true,
                            searching: true,
                            "data": filteredOrgs,
                            orderCellsTop: true,
                            fixedHeader: true,
                            "rowId": "_id",
                            "pageLength": 10,
                            order: [[1, "asc"]],
                            "columns": [
                                { "data": "_id", visible: false },
                                { "data": "name" },
                                { "data": "address.street" },
                                { "data": "address.city" }
                            ],
                            createdRow: function (row, data, dataIndex) {

                            }
                        });
                        $('#tblPickerOrganizations tbody').on('click', 'tr', function () {
                            document.getElementById('orgID').value = table.row(this).id();
                            $(this).toggleClass('selected');
                        });

                        $('.dataTables_length select').addClass('form-select');
                        $('.dataTables_filter input').addClass('form-control');

                        $(document).ready( function () {
                            function focusSearchInput(){
                                $('div.dataTables_filter input').focus();
                            }
                            setTimeout(focusSearchInput, 1)
                        });
                    }
                }
            },
            preConfirm: () => {
                const org_id = Swal.getPopup().querySelector('#orgID').value
                if (!org_id) {
                    Swal.showValidationMessage(`Bitte wählen Sie eine Organisation.`)
                }
                return { org_id }
            }
        })
            .then(async results => {
                if (results?.isConfirmed) {
                    const org_id = results.value.org_id

                    if( !hasPermission({user, scopes:['organization.switch.any', 'organization.switch.own']}) ) return;

                    const updatedMgmtUser = MgmtUserAddOrg(org_id);

                    setIsLoading(true);
                    updateUserAccessOrgs({user_id: updatedMgmtUser._id, payload:updatedMgmtUser})
                        .then(async(response)=>{
                                if(typeof response.user === 'object' && response.user !== null) {
                                    successToast(org_id + ' wurde erfolgreich zu den Kundenzugängen hinzugefügt')
                                    setMgmtUser(response.user);
                                    setIsLoading(false);
                                }else {
                                    errorToast("Es ist ein Fehler aufgetreten beim Hinzufügen der neuen Organisation.")
                                    setIsLoading(false);
                                }
                        })
                        .catch(async (err) => {
                            setIsLoading(false);
                            errorToast('Es ist ein Fehler aufgetreten beim Hinzufügen der neuen Organisation.')
                            console.error(err);

                        });
                }
            })
    }

    const doYouReallyWantToSave = (orgID) => {
        return new Promise(async (resolve) => {
            if (window.sessionStorage && !window.sessionStorage.getItem("yeswecan")) {
                const answer = await Swal.fire({
                    title: 'Sicher?',
                    input: 'checkbox',
                    text: 'Möchten Sie wirklich diese Organisation entfernen: '+ orgID,
                    inputPlaceholder: 'Nicht mehr fragen',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: "Nein",
                })
                if (!answer.isConfirmed)
                    return resolve(false);
                if (answer.value === 1) {
                    window.sessionStorage.setItem("yeswecan", true);
                    return resolve(true);
                }
            }
            return resolve(true);
        })
    }

    const deleteUserOrg = async (orgID) => {

        if (orgID === mgmtUser.organization_id) {
            errorToast('Sie können die Hauptorganisation des Benutzers nicht löschen.');
            return;
        } else {
            const doSave = await doYouReallyWantToSave(orgID)
            if (!doSave) {
                return
            }
            const updatedMgmtUser = {...mgmtUser};
            updatedMgmtUser.organizations = updatedMgmtUser.organizations.filter(org => org !== orgID);

            setIsLoading(true);
            updateUserAccessOrgs({user_id: updatedMgmtUser._id, payload: updatedMgmtUser})
                .then(async (response) => {
                    if (typeof response.user === 'object' && response.user !== null) {
                        successToast(orgID + ' wurde erfolgreich aus den Kundenzugängen gelöscht.')
                        setMgmtUser(response.user);
                        setIsLoading(false);

                    } else {
                        errorToast("Etwas ist schiefgegangen beim Entfernen der Organisation.")
                        setIsLoading(false);
                    }
                })
                .catch(async (err) => {
                    setIsLoading(false);
                    errorToast("Etwas ist schiefgegangen beim Entfernen der Organisation.")
                });
        }
    }

    const MgmtUserAddOrg = (newItem) => {

        const updatedUser = { ...mgmtUser };
        updatedUser.organizations = [...mgmtUser.organizations, newItem];
        return updatedUser;
    };



    const UI = () => {

        const getOrgName = (org_id) => {
            const org = organizations.filter(row => row._id === org_id)
            if(org.length !== 0) return org[0].name
            return org_id
        }

        if (Object.keys(mgmtUser).length === 0) {
            return (<Fragment></Fragment>)
        }

        const RenderOrgEditButtons = () => {
            if (isOrgsEdit === true) {
                return (
                    <div className="col-12">
                        <button className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={() => { setIsOrgsEdit(false) }}>Deaktivieren</button>
                    </div>
                )
            } else {
                if ( (user?.scopes?.includes('multi.organization.assign.any') || user?.roles?.includes('god')) ) {
                    return (
                        <div className="col-12">
                            <button className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" onClick={() => { setIsOrgsEdit(true) }} >Aktivieren</button>
                        </div>
                    )
                }
            }
            return (<Fragment></Fragment>);
        }

        const UserDetailForm = () => {

            const RenderConditionalButtons = () => {
                if (isEditingUser === true) {
                    return (
                        <div className="col-12">
                            <button className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => { doUpdateUser(e) }}>Speichern</button>
                            <button className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => { cancelUserEdit(e) }}>Abbrechen</button>
                        </div>
                    )
                } else {
                    if ((user?.scopes?.includes('profile.update.own') && mgmtUser?._id === user?._id) || user?.scopes?.includes('profile.update.any') || user?.scopes?.includes('user.edit.any') || user?.scopes?.includes('user.edit.own') || user?.roles?.includes('god')) {
                        return (
                            <div className="col-12">
                                <button className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => { editUser(e) }}>Anpassen</button>
                            </div>
                        )
                    }
                }
                return (<Fragment></Fragment>);
            }

            return (
                <Fragment>
                    {isUpdating && <ProcessingBlocker/>}
                    <form className="validate-form" noValidate="noValidate" id="frmUserUpdate">
                        <div className="row">
                            <div className="col-12 col-sm-6 mb-1">
                                <label className="form-label" htmlFor="accountFirstName">Vorname</label>
                                <input type="text" className="form-control usrAccount" id="accountFirstName" name="first_name" onChange={(e)=>updateForm('first_name', e.target.value)} value={isEditingUser ? ((editForm?.first_name) ? capitalize(editForm.first_name) : '') : (mgmtUser?.first_name) ? capitalize(mgmtUser.first_name) : ''} disabled={!isEditingUser} />
                            </div>
                            <div className="col-12 col-sm-6 mb-1">
                                <label className="form-label" htmlFor="accountLastName">Nachname</label>
                                <input type="text" className="form-control usrAccount" id="accountLastName" name="last_name"  onChange={(e)=>updateForm('last_name', e.target.value)} value={isEditingUser ? ((editForm?.last_name) ? capitalize(editForm.last_name) : '') : (mgmtUser?.last_name) ? capitalize(mgmtUser.last_name) : ''} data-msg="Bitte Nachname eingeben" disabled={!isEditingUser} />
                            </div>
                            <div className="col-12 col-sm-6 mb-1">
                                <label className="form-label" htmlFor="accountPhoneNumber">Telefonnummer</label>
                                <input type="text" className="form-control usrAccount" id="accountPhoneNumber" name="phone" placeholder="Telefonnummer" onChange={(e)=>updateForm('phone', e.target.value)} value={isEditingUser ? ((editForm?.phone) ? editForm.phone : '') : (mgmtUser?.phones[0]?.number) ? mgmtUser.phones[0].number : ''} disabled={!isEditingUser} />
                            </div>
                            <PermissionsWrapper
                                user={user}
                                scopes={['organization.view.any', 'organization.view.own']}
                            >
                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label" htmlFor="accountOrganization">Organisation</label>
                                    <div className="input-group">
                                        <span className="input-group-text"><FontAwesomeIcon icon={faPeopleGroup} /></span>
                                        <select type="select" className="form-select" id="accountOrganization" name="organization_id" onChange={(e)=>updateForm('organization_id', e.target.value)} disabled={!isEditingUser}>
                                            {organizations.map(org => (<option key={uniqId()} value={org._id} selected={isEditingUser ? (org._id === editForm?.organization_id) : (mgmtUser !== undefined && mgmtUser.organization) && mgmtUser.organization._id === org._id}>{org.name}</option>))}
                                        </select>
                                    </div>
                                </div>
                            </PermissionsWrapper>
                            <div className={ mgmtUser.emails &&  mgmtUser.emails.length > 1?"col-12 mb-1":"col-12 col-sm-6 mb-1"}>
                                <label className="form-label" htmlFor="accountEmail"> E-Mail</label>{/*{isEditingUser && <button className="ms-1 floar-right btn btn-primary btn-sm waves-effect waves-float waves-light" onClick={(e)=>addUserEmail(e)}><i class="fa-solid fa-plus"></i></button> } **/}
                                { mgmtUser?.emails?.map((em, i)=>{
                                    return (
                                        <div key={uniqId()} className={ mgmtUser.emails.length > 1 && i > 0?"mt-25":""} style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                            <div className={ mgmtUser.emails.length > 1?"input-group input-group":"input-group"}>
                                                {/*mgmtUser.emails.length <= 1 && i === 0 && <span className='input-group-text' style={{fontWeight:"bold", fontSize:'0.857rem', textTransform:"uppercase"}}>Primär</span> */}

                                                <input type="email" className="form-control usrEmail" id="accountEmail" name="email" defaultValue={em?.email? em?.email : 'Keine E-Mail vorhanden'} onChange={(e)=>{em.email = e.target.value}} aria-invalid="false" disabled={!isEditingUser || !em?.new} />
                                                {!!em?.verified_at && !isEditingUser ?
                                                    <span className='input-group-text bg-primary text-white' style={{fontWeight:"bold", fontSize:'0.857rem', textTransform:"uppercase", padding:"0 1.6rem"}}>Verifiziert</span>
                                                    :
                                                    <Fragment>
                                                        {!isEditingUser && <span className='input-group-text bg-secondary text-white' style={{fontWeight:"bold", fontSize:'0.857rem', textTransform:"uppercase"}}>Unverifiziert</span> }
                                                        {/*{isEditingUser && i !== 0 && !em.verified_at && <button className="btn bg-danger text-white" onClick={(e)=>removeUserEmail(e, i)}><FontAwesomeIcon icon={faXmark} /></button>}*/}
                                                        {/*i === 0 && <span className='input-group-text' style={{fontWeight:"bold", fontSize:'0.857rem', textTransform:"uppercase"}}>Primär</span> */}
                                                    </Fragment>
                                                }

                                            </div>

                                        </div>
                                    );
                                })}
                            </div>

                            <PermissionsWrapper
                                user={user}
                                roles={['realm_admin', 'realm_manager']}>
                                {getUserVerificationInfo()}
                            </PermissionsWrapper>

                            <div className="col-12 col-sm-6 mb-1">
                                <AddressField />
                            </div>

                            <div className="col-12 col-sm-6 mb-1">
                                <label className="form-label" htmlFor="accountState">Bundesland</label>
                                <input type="text" className="form-control usrAccount" id="accountState" name="state" placeholder="Bundesland" defaultValue={(mgmtUser !== undefined && mgmtUser.addresses && mgmtUser.addresses[0]) ? capitalize(mgmtUser.addresses[0].province) : ''} disabled />
                            </div>
                            <div className="col-12 col-sm-6 mb-1">
                                <label className="form-label" htmlFor="accountZipCode">PLZ</label>
                                <input type="text" className="form-control usrAccount" id="accountZipCode" name="zipCode" placeholder="PLZ" value={(mgmtUser !== undefined && mgmtUser.addresses && mgmtUser.addresses[0]) ? mgmtUser.addresses[0].post_code : ''} maxLength="10" disabled />
                            </div>
                            <div className="col-12 col-sm-6 mb-1">
                                <label className="form-label" htmlFor="accountZipCode">Land</label>
                                <input type="text" className="form-control usrAccount" id="accountCountry" name="country" placeholder="Land" value={(mgmtUser !== undefined && mgmtUser.addresses && mgmtUser.addresses[0]) ? mgmtUser.addresses[0].country : ''} maxLength="2" disabled />
                            </div>

                            {RenderConditionalButtons()}

                        </div>
                    </form>
                </Fragment>
            )
        }

        const profileTitle = () => {
            if (mgmtUser !== undefined) {
                let title = `${capitalize(mgmtUser.first_name)} ${capitalize(mgmtUser.last_name)}`

                if (postalAddress) {
                    title += ' - ' + postalAddress;
                }

                return (`${title}`)
            }
            return ('Benutzer');
        }

        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-12">
                                <h3 className="content-header-title float-start mb-0">{mgmtUser.emails && mgmtUser.emails[0]&& mgmtUser.emails[0].email?mgmtUser.emails[0].email:`${mgmtUser.first_name} ${mgmtUser.last_name}`}</h3>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Verwaltung</a></li>
                                        <li className="breadcrumb-item active">
                                            {(user?.scopes?.includes('user.view.any') || user?.scopes?.includes('user.view.own')) ?
                                                <NavLink className="breadcrumb-navLink" to="/admin/users">Benutzer</NavLink>
                                                :"Benutzer"
                                            }</li>
                                        <li className="breadcrumb-item active">Details
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.users.details}  isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>


                <div className="content-body">
                    <div className="row"> </div>

                    <section id="userData">
                        <div className="row">

                            <div className="col-12">
                                <ul className="nav nav-pills mb-2">

                                    <li className="nav-item">
                                        <a className={`nav-link ${(tabActive === 'account') ? 'active' : ''}`} onClick={() => { setTabActive('account') }}>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                            <span className="fw-bold">Details</span>
                                        </a>
                                    </li>

                                    {/* <li className="nav-item ">
                                        <a className={`nav-link ${(tabActive === 'notifications')? 'active': 'text-muted'}`} onClick={ () => {setTabActive('notifications')} }>
                                        <FontAwesomeIcon icon={faUser} />
                                        <span className="fw-bold">Benachrichtigungen</span>
                                        </a>
                                    </li> */}
                                    {(hasPermission({scopes: ['user.update.own'], user: user})) &&
                                        <li className="nav-item" id="userDetailsSecurityListItem">
                                            <a className={`nav-link ${(tabActive === 'security') ? 'active' : ''}`} onClick={() => { setTabActive('security') }}>
                                            <i className="fa-solid fa-lock"></i>
                                                <span className="fw-bold">Sicherheit</span>
                                            </a>
                                        </li>
                                    }

                                    {/* <li className="nav-item">
                                        <a className={`nav-link ${(tabActive === 'billing')? 'active': 'text-muted'}`} onClick={ () => {setTabActive('billing')} }>
                                        <FontAwesomeIcon icon="fa-solid fa-ribbon" />
                                        <span className="fw-bold">Zahlungen</span>
                                        </a>
                                    </li> */}
                                    {(hasPermission({roles: ['realm_admin', 'realm_manager'], user: user})) &&
                                        <li className="nav-item" id="userDetailsAdminListItem">
                                            <a className={`nav-link ${(tabActive === 'administration') ? 'active' : ''}`} onClick={() => { setTabActive('administration') }}>
                                            <i className="fa-solid fa-wrench"></i>
                                                <span className="fw-bold">Verwalten</span>
                                            </a>
                                        </li>
                                    }

                                </ul>


                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content pt-1">

                                    <div className={`tab-pane ${(tabActive === 'account') ? 'active' : ''}`} id="account" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">{profileTitle()} </h4>
                                            </div>
                                            <div className="card-body py-2 my-25" id="userDetailsDataForm">

                                                {UserDetailForm()}

                                            </div>
                                        </div>
                                    </div>

                                    <div className={`tab-pane ${(tabActive === 'notifications') ? 'active' : ''}`} id="billing" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Benachrichtigungen </h4>
                                            </div>
                                            <div className="card-body py-2 my-25">
                                                <form className="validate-form mt-2 pt-50" noValidate="noValidate">
                                                    <div className="row">

                                                        <div className="col-12 col-sm-6 mb-1">
                                                            <h4>Noch nicht verfügbar</h4>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={`tab-pane ${(tabActive === 'security') ? 'active' : ''}`} id="security" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Passwort ändern</h4>
                                            </div>
                                            <div className="card-body py-2 my-25">
                                                <form className="validate-form" ref={passwordForm} noValidate="noValidate">
                                                    <div className="row">

                                                        <div className="col-12 mb-1">
                                                            <label className="form-label" htmlFor="accountCurPassword">Aktuelles Passwort</label>
                                                            <input type="password" className="form-control form-control-lg" id="accountCurPassword" name="password" onChange={ (e) => setIsPasswordChanged(true) } ref={password} />
                                                        </div>
                                                        <div className="col-12 mb-1">
                                                            <label className="form-label" htmlFor="accountNewPassword">Neues Passwort</label>
                                                            <input type="text" className="form-control form-control-lg" id="accountNewPassword" name="new_password"  onChange={ (e) => doVerifyPasswords(e) } ref={newPassword} />
                                                        </div>

                                                        { passwordErrors && passwordErrors.length > 0 &&
                                                            passwordErrors.map((error)=> {
                                                                return (
                                                                    <div className="password-error text-danger text-bold">
                                                                        {error}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div className="col-12">
                                                            <button className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e)=>doChangePassword(e)}>Speichern</button>
                                                            <button className="btn btn-success mt-1 float-right waves-effect waves-float waves-light" title="Generates a valid password for you to copy and store." onClick={ (e) => generatePassword({e}) }><i class="fa-solid fa-binary-lock"></i> Password Generator </button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        {/* <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">2-Faktor Authentifizierung</h4>
                                            </div>
                                            <div className="card-body py-2 my-25">
                                                <form className="validate-form mt-2 pt-50" noValidate="noValidate">
                                                    <div className="row">

                                                        <div className="col-12 col-sm-6 mb-1">
                                                            <h4>Noch nicht verfügbar</h4>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className={`tab-pane ${(tabActive === 'billing') ? 'active' : ''}`} id="billing" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h4 className="card-title">Abonements & Zahlungsarten</h4>
                                            </div>
                                            <div className="card-body py-2 my-25">
                                                <form className="validate-form mt-2 pt-50" noValidate="noValidate">
                                                    <div className="row">

                                                        <div className="col-12 col-sm-6 mb-1">
                                                            <h4>Noch nicht verfügbar</h4>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`tab-pane ${(tabActive === 'administration') ? 'active' : ''}`} id="administration" role="tabpanel" aria-labelledby="home-tab-fill">
                                        <div className="row">

                                            <div className="col-lg-8 h-100">
                                                <OptionalContent>
                                                    {(hasPermission({ scopes: ['role.grant.own', 'role.grant.any'], user: user})) &&
                                                        <button type="button" id="btnChangeRoles" className="btn btn-primary waves-effect waves-float waves-light me-1 mb-25" onClick={async (e) => {
                                                            e.preventDefault();
                                                            let status = await changeRoles({ userModel: mgmtUser });
                                                            if (status && status === "OK") getMgmtUser();
                                                        }}>
                                                            <i className="me-50 fa-solid fa-lock-keyhole-open"></i> Rollen ändern
                                                        </button>
                                                    }
                                                    {(hasPermission({ scopes: ['user.invite.any'], user: user})) &&
                                                        <button type="button" className="btn btn-primary waves-effect waves-float waves-light me-1 mb-25" disabled={mgmtUser.roles.includes('authenticated_user') ? true : false} onClick={async (e) => {resendInvite(mgmtUser._id)}}>
                                                            <i class="fa-solid fa-envelope"></i> Einladungs-Email erneut senden
                                                        </button>
                                                    }
                                                    {/**
                                                    {hasPermission({user: user, scopes:['user.update.any']}) &&
                                                        <button type="button" id="btnResendVerificationMail" className="btn btn-secondary waves-effect waves-float waves-light mb-25" onClick={async (e) => {
                                                            e.preventDefault();
                                                            let answer = await Swal.fire({
                                                                iconHtml: '<i class="fa-solid fa-paper-plane"/>',
                                                                customClass: {
                                                                    icon: "border-0"
                                                                },
                                                                title: 'Verifizierungsmail erneut versenden?',
                                                                html: 'Falls die Email <span class="bg-primary rounded-pill badge">' + mgmtUser.emails[0].email + '</span> noch nicht verifiziert wurde, wird damit eine weitere Verfizierungsemail verschickt. Sind Sie sicher?',
                                                                cancelButtonText: 'Nein',
                                                                confirmButtonText: 'Ja',
                                                                showCancelButton: true,

                                                            })
                                                            if(answer.isConfirmed) {
                                                                sendVerificationMail({user_id:mgmtUser._id, email:mgmtUser.emails[0].email})
                                                            }
                                                        }}><FontAwesomeIcon icon={faPaperPlane} className="me-50" /> Verifizierungsmail</button>
                                                    }
                                                    */}
                                                </OptionalContent>
                                                {( hasPermission({ scopes: ['role.view.any','role.view.own', 'permission.view.any'], user: user})) &&
                                                    <div className="card">
                                                        <div className="card-header border-bottom">
                                                            <h4 className="card-title"><FontAwesomeIcon icon={faSquareCheck} className="me-50" />Rechte</h4>
                                                        </div>
                                                        <div className="card-body py-2 my-25">
                                                            {mgmtUser !== undefined &&
                                                                <Fragment>
                                                                    <h4>Benutzerrollen ({mgmtUser.roles.length})</h4>
                                                                    <div className="mb-2" style={{ fontSize: "1.2rem" /*display:"grid", gridGap:'5px', gridTemplateColumns:'repeat(7, auto)'*/ }}>
                                                                        {mgmtUser.roles.map((role) => {
                                                                            return (
                                                                                <span key={uniqId()} className="bg-primary badge rounded-pill me-25 mb-25">{role}</span>
                                                                            )
                                                                        })}
                                                                    </div>

                                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                                        <div style={{ flex: "1" }}><h4>Berechtigungen ({mgmtUser.scopes.length})</h4></div>
                                                                        <div><input type="text" className="form-control small usrAccount mb-1" style={{ height: "30px" }} id="scopesFilter" name="scopesFilter" placeholder="Filter ..." onChange={(e) => setScopesFilter(e.target.value)} /></div>
                                                                    </div>
                                                                    <div className="mb-2" style={{/* display: "grid", gridGap: '5px', fontSize: '0.8em', lineHeight: '1em', gridTemplateColumns: 'repeat(6, minmax(50px, 17%))'*/ }}>
                                                                        {mgmtUser.scopes.map((scope, i) => {
                                                                            if (scopesFilter && scope.indexOf(scopesFilter) < 0) return null;
                                                                            return (
                                                                                <span className="bg-secondary badge rounded-pill me-50 mt-50">{scope}</span>
                                                                                // <div key={uniqId()} className="bg-secondary text-white" style={{ borderRadius: "20px", textTransform: 'uppercase', padding: "0.5rem 1rem 0.5rem 1rem", fontSize: '11px', fontWeight: 'bold' }}>
                                                                                //     <span>{scope}</span>
                                                                                // </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <PermissionsWrapper
                                                    user={user}
                                                    scopes={['organization.switch.any']}
                                                >

                                                    <div className="card" id="UserAccessOrg" >
                                                        <div className="card-header border-bottom">
                                                            <h4 className="card-title">Kundenzugänge</h4>
                                                            <div className="heading-elements">
                                                                <PermissionsWrapper
                                                                    user={user}
                                                                    scopes={['multi.organization.assign.any']}
                                                                >
                                                                    <button className="btn btn-primary " id="userCreateBtn"
                                                                                disabled={!isOrgsEdit}
                                                                            onClick={() => { addUserOrgs() }} ><FontAwesomeIcon icon={faPlus} />
                                                                    </button>

                                                                </PermissionsWrapper>

                                                            </div>

                                                        </div>
                                                        <div className="card-body py-2 my-25">
                                                            {isLoading ?
                                                                <div className="text-center">
                                                                    <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                            }

                                                            {mgmtUser.organizations.map((org, index) => {
                                                                return (
                                                                    <div key={uniqId()} className="badge rounded-pill bg-secondary organisationNames" >
                                                                        <span >{getOrgName(org)}</span>
                                                                        {org !== mgmtUser.organization_id &&
                                                                        isOrgsEdit &&
                                                                        hasPermission({user: user, scopes: ['multi.organization.assign.any']}) ?
                                                                            <FontAwesomeIcon  onClick={() => deleteUserOrg(org)} style={{ marginLeft: "5px", cursor: "pointer" }} icon={faClose} />
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                            <br></br>

                                                            <small>Berechtigungen müssen über die Nutzeransicht bei dem jeweiligen Kunden angepasst werden </small>

                                                            {RenderOrgEditButtons()}

                                                        </div>
                                                    </div>
                                                </PermissionsWrapper>
                                            </div>

                                            <div className={user?.roles?.includes('god') || user?.scopes?.includes('profile.delete.any') || (user?.scopes?.includes('profile.delete.own') && mgmtUser?._id === user?._id) ? "col-lg-4" : "col-lg-12"}>
                                                <div className="card">
                                                    <div className="card-header border-bottom">
                                                        <h4 className="card-title"><FontAwesomeIcon icon={faTrash} className="me-50" />Account löschen</h4>
                                                    </div>
                                                    <div className="card-body py-2 my-25 ms-50" style={{ maxWidth: "330px" }}>
                                                        <h4 className="">Sind Sie sich sicher, dass Sie diesen Account löschen wollen?</h4>
                                                        <div className="mb-1" style={{ display: 'flex', alignItems: "center" }}><FontAwesomeIcon icon={faBrakeWarning} className='me-1 text-danger' size="2x" />Diese Aktion ist endgültig, sobald der Account gelöscht wurde, ist er nicht mehr reaktivierbar.</div>
                                                        <form className="validate-form">
                                                            <div className="form-check" style={{ fontSize: "1.2em" }}>
                                                                <input className="form-check-input" type="checkbox" defaultChecked={deactivateActive} onChange={() => setDeactivateActive(!deactivateActive)} name="accountActivation" id="accountActivation" data-msg="Bitte die Löschung des" />
                                                                <span className="badge bg-danger"><label className="form-check-label" htmlFor="accountActivation">{(mgmtUser !== undefined && mgmtUser.emails[0]) ? `${mgmtUser.emails[0].email} löschen` : 'Ja, ich bin mir sicher'}</label></span>
                                                            </div>
                                                            <button type="button" id="btnDeactivateAccount" className="btn btn-danger mt-1 waves-effect waves-float waves-light" disabled={!deactivateActive} onClick={(e) => { doDeactivateUser(e) }}>Account löschen</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                    </section>

                </div>




            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )


}


UserDetails.requiredScope = ['user.view.own', 'user.view.any', 'profile.view.any', 'profile.view.own'];
UserDetails.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default UserDetails;