import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';

import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {LocationsContext} from "../../../../context/Locations/Locations";
import {MgmtUsersContext} from "../../../../context/Users/UsersContext";
import {SensorsContext} from "../../../../context/Devices/Sensors";
import {GatewaysContext} from "../../../../context/Devices/Gateways";
import {TenantContext} from "../../../../context/Tenant/Tenant";


import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import {useRouter} from "../../../../components/Shared/Router/Router";
import Swal from '@molline/sweetalert2'

import LocationUsersTable from "../../../../components/Dashboard/tables/dataTables/tblLocationUsers";
import TblUsers from "../../../../components/Dashboard/tables/dataTables/tblUsers";
import LocationsTable from "../../../../components/Dashboard/tables/dataTables/tblLocations";
import SensorsTable from "../../../../components/Dashboard/tables/dataTables/tblSensors";
import GatewaysTable from "../../../../components/Dashboard/tables/dataTables/tblGateways";
import CommentTable from "../../../../components/Dashboard/tables/dataTables/tblComments";

import ConsumptionChart from "../../../../components/Dashboard/charts/consumptionChart";
import TagComponent from "../../../../components/Dashboard/tagsComponent";
import de from '../../../../components/Dashboard/tables/datatable.de.json'

import {Link} from "react-router-dom";
import Axios_client from "../../../../utils/axios_client";
import cookies from "js-cookie";
import {hasPermission, PermissionsWrapper} from "../../../../components/Dashboard/reactPermissionsWrapper";
import PageTour from "../../../../components/Dashboard/tour/tour";
import TourSteps from "../../../../TourSteps.json";
import capitalizeUtil from "../../../../utils/capitalizeUtils";

const $ = require('jquery');
$.DataTable = require('datatables.net');

const LocationDetails = (props) => {

    const { user } = useContext(AuthContext);
    const router = useRouter()
    const { location_id } = router.query
    const { gateways } = useContext(GatewaysContext);
    const { sensors } = useContext(SensorsContext);
    const { tenant, tenants, getCurrentTenant, currTenant} = useContext(TenantContext);
    const { mgmtUsers } = useContext(MgmtUsersContext);
    const {
        location,
        locations,
        setLocation,
        getLocation,
        locationUpdate,
        addChildLocation,
        removeChildLocation,
        addParentLocation,
        removeParentLocation,
        locationGroups,
        addComment,
        deleteComment,
        addLog,
        deleteLog,
        locationIcon,
        errorToast,
        successToast,
        doYouReallyWantToSave,
        addLocationUser,
        removeLocationUser,
        toggleUviAttribute
    } = useContext(LocationsContext);

    //const [address, setAddress] = useState('');

    const [locationParents, setLocationParents] = useState([]);
    const [locationChildren, setLocationChildren] = useState([]);
    const [locationUsers, setLocationUsers] = useState([]);
    const [locationSensors, setLocationSensors] = useState([]);
    const [tabActive, setTabActive] = useState('details')

    const locationsChanged = useCompare(locations)
    const locationChanged = useCompare(location)
    const locationIdChanged = useCompare(location_id)
    const sensorsChanged = useCompare(sensors)
    const groupsChanged = useCompare(locationGroups)
    const [isEditing, setIsEditing] = useState(false)
    const [editForm, setEditForm] = useState({})
    // using the variable from the context results in an infinite loop
    const mgmtUsersChanged = useCompare(mgmtUsers)

    useEffect(() => {
        if (locationIdChanged === true) {
            getLocation(location_id)  
        }
        if (locationChanged === true && location.address !== undefined) {
            resolveParents()
            resolveSensors()
            resolveChildren()
            resolveUsers()
        }
        // this prevents the bug that if a location is updated/page is reloaded the first location in the array is selected as current location
        if(locationsChanged) getLocation(location_id)  

        if(mgmtUsersChanged) resolveUsers()
    }, [user, currTenant, location_id, locationIdChanged, location, locationChanged, sensors, locationSensors, sensorsChanged, gateways, mgmtUsers, groupsChanged, locations, locationsChanged, locationParents, locationChildren,  isEditing, locationUsers     /**  , , , locationSensors, mgmtUsers, funkSensors, nFunkSensors,**/]);


    function useCompare(val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const resolveParents = () => {
        if(!location.parents) return
        let parents = locations.filter( l => location.parents.includes(l._id))
        setLocationParents(parents) 
    }

    const resolveChildren = () => {
        if(!location.children) return
        let children = locations.filter( l => location.children.includes(l._id))
        setLocationChildren(children)
    }
    /**
     * Users authorized at this location can be both in this location
     * and in the location's parents. We will need an array of both.
     */
    const resolveUsers = () => {
        if(!location.users) return
        let users = mgmtUsers.filter( u => location.users.includes(u._id))
        if(locationParents.length > 0){
            for(let ploc of locationParents){
                let pUsers = mgmtUsers.filter( u => ploc.users.includes(u._id))
                let array3 = users.concat(pUsers);
                users = array3;
            }
        }
        setLocationUsers(users)
    }

    const resolveSensors = () => {
        if(!location.sensors) return
        let locationSensors = sensors.filter((s) => location.sensors.includes(s.serial_number))
        setLocationSensors(locationSensors)
    }
    /**
     * Remove a location realtionship 
     */
    const removeLocation = async ({ subject_id, type }) => {
        const doSave = await doYouReallyWantToSave()
        if (!doSave) {
            return
        }
        switch (type) {
            case 'children':
                removeChildLocation({ target_id: location._id, child_id: subject_id })
                    .then(response => {
                        $('#tblLocationsList').DataTable().destroy();
                        successToast('Beziehung wurde erfolgreich gelöscht')
                    })
                    .catch(err => {
                        console.log('ERROR 819', err)
                        errorToast(err)
                    })
                break;
            case 'parent':
                removeParentLocation({ target_id: location._id, parent_id: subject_id })
                    .then(response => {
                        $('#tblLocationsList').DataTable().destroy();
                        successToast('Beziehung wurde erfolgreich gelöscht')
                    })
                    .catch(err => {
                        console.log('ERROR 827', err)
                        errorToast(err)
                    })
                break;
            default:
                break;
        }
    }
    
    const addBldgManager = async () => {
        
        const addUserTbl = () => {
            if (mgmtUsers.length !== 0) {
                const filteredUsers = mgmtUsers.filter(usr => !location.users.includes(usr._id))
                const userAddTable = $('#tblAddLocationUsers').DataTable({
                    "id": '#tblAddLocationUsers',
                    "language": de,
                    "data": filteredUsers,
                    "columns": [
                        { "data": "_id" },
                        {
                            "data": "organization", render: function (data, type, row) {
                                return data.name;
                            }
                        },
                        {
                            "data": "first_name", render: function (value, type, row) {
                                return capitalizeUtil.capitalize(value);
                            }
                        },
                        {
                            "data": "last_name", render: function (value, type, row) {
                                return capitalizeUtil.capitalize(value);
                            }
                        },
                        { "data": "emails[0].email" },
                    ],
                    "options": {
                        "rowId": "_id",
                    },

                })
                $('#tblAddLocationUsers tbody').on('click', 'tr', function () {
                    $(this).toggleClass('selected');
                    const idArray = $.map(userAddTable.rows('.selected').data(), function (item) {
                        return item._id
                    })
                    document.getElementById('userIdsToAdd').value = idArray;
                });
            }
        }

        Swal.fire({
            width: "90%",
            title: 'Gebäudeverwalter zur Nutzereinheit hinzufügen',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Hinzufügen',
            cancelButtonText: 'Abbrechen',
            html:
                `Nach Benutzer suchen und diese per Klick auswählen, um sie der Nutzereinheit zuzuordnen.
                    <input type="text" id="userIdsToAdd" hidden/>
                    <table id="tblAddLocationUsers" class="table mb-0 row-border hover order-column w-100" role="grid">
                        <thead>
                            <tr>
                                <th >ID</th>
                                <th >Organisation</th>
                                <th >Vorname</th>
                                <th >Nachname</th>
                                <th >Email</th>
                            </tr>
                        </thead>
                    </table>`,
            willOpen: () => {
                addUserTbl()
            },
            preConfirm: (inputValue) => {
                const user_ids = Swal.getPopup().querySelector('#userIdsToAdd').value
                if (!user_ids) {
                    Swal.showValidationMessage(`Bitte Benutzer auswählen.`)
                }
                return { user_ids }
            }
        })
            .then(async results => {
                if (results.isConfirmed) {
                    const userIdArray = results.value.user_ids.split(',')
                    try {
                        for (const myUserId of userIdArray) {
                            await addLocationUser(location._id, myUserId)
                        }
                    } catch (err) {
                        console.log('error at adding user to location', err);
                        errorToast('Benutzer konnte nicht zugewiesen werden')
                    }
                    successToast('Benutzer wurde(n) erfolgreich der Nutzereinheit zugewiesen')
                }
                $('#tblAddLocationUsers').DataTable().destroy();
            })
    }

    const removeUser = async (user_id) => {
        const doSave = await doYouReallyWantToSave()
        if (!doSave) {
            return
        }
        try {
            await removeLocationUser(location._id, user_id)
            successToast('Benutzer wurde erfolgreich entfernt')            
        } catch (err) {
            console.log('error at removing user from location', err);
            errorToast('Fehler beim Entfernen des Benutzers')
        }
    }

    const createRelationLocation = async (type) => {
        let swalTitle = ''
        // add parent location only possible when current location is not a multi premise
        // add child locations only possible when current location is not a minor_premise
        let allowedLocations = []
        if (type === 'parent') {
            swalTitle = 'Übergeordnete Nutzer-/Mieteinheit hinzufügen'
            if (location.type === 'single_premise') {
                allowedLocations = locations.filter(myLocation => ( myLocation.type === 'multi_premise' && myLocation._id !== location._id ))
            } 
        } else if (type === 'child') {
            swalTitle = 'Untergeordnete Nutzer-/Mieteinheit hinzufügen'
            if(location.type === 'multi_premise') {
                allowedLocations = locations.filter(myLocation => myLocation.type === 'single_premise' && myLocation.parents.length === 0 && myLocation._id !== location._id)
            }
        } else {
            return
        }
        const addToLocationTbl = () => {
            if (locations.length !== 0) {
                const myLocationTable = $('#addToLocationTbl').DataTable({
                    "language": de,
                    paging: true,
                    "pagingType": "full",
                    processing: true,
                    searching: true,
                    "data": allowedLocations,
                    orderCellsTop: true,
                    fixedHeader: true,
                    "rowId": "_id",
                    "columns": [
                        {
                            "data": null,
                            "searchable": false,
                            "ordering": true,
                            render: function (data, type, row) {
                                return locationIcon({location:data})
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.nickname !== undefined) {
                                    return capitalizeUtil.capitalize(data.nickname)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.street !== undefined) {
                                    return capitalizeUtil.capitalize(data.address.street)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.house_number !== undefined) {
                                    return data.address.house_number
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.street1 !== undefined) {
                                    return data.address.street1
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.city !== undefined) {
                                    return capitalizeUtil.capitalize(data.address.city)
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                if (data.address.post_code !== undefined) {
                                    return data.address.post_code
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            "data": null,
                            "searchable": true,
                            "ordering": true,
                            render: function (data, type, row) {
                                return 'DE'
                            }
                        },
                    ],
                });
                $('#addToLocationTbl tbody').on('click', 'tr', function () {
                    $(this).toggleClass('selected');
                    const idArray = $.map(myLocationTable.rows('.selected').data(), function (item) {
                        return item._id
                    })
                    document.getElementById('locationIdsToAdd').value = idArray;
                });
            }
        }

        Swal.fire({
            width: "90%",
            title: swalTitle,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Hinzufügen',
            cancelButtonText: 'Abbrechen',
            html:
                `Nach einer Nutzer-/Mieteinheit suchen und auswählen, um sie der aktuellen Nutzer-/Mieteinheit zuzuordnen.` +
                '<input type="text" id="locationIdsToAdd" hidden/>' +
                `<table id="addToLocationTbl" class="table mb-0 row-border hover order-column " role="grid" width="100%">` +
                    '<thead>' +
                        '<tr>' +
                            '<th></th>' +
                            '<th>Name</th>' +
                            '<th>Straße</th>' +
                            '<th>Hausnr.</th>' +
                            '<th>ME</th>' +
                            '<th>Stadt</th>' +
                            '<th>PLZ</th>' +
                            '<th>Land</th>' +
                        '</tr>' +
                    '</thead>' +
                '</table>',
            willOpen: () => {
                addToLocationTbl()
            },
            preConfirm: (inputValue) => {
                const location_ids = Swal.getPopup().querySelector('#locationIdsToAdd').value
                if (!location_ids) {
                    Swal.showValidationMessage(`Bitte eine Nutzer-/Mieteinheit auswählen.`)
                }
                return { location_ids }
            }
        })
            .then(async results => {
                if (results.isConfirmed) {
                    const locationIdArray = results.value.location_ids.split(',')
                    if (type === 'parent') {
                        for (const parentId of locationIdArray) {
                            await addParentLocation({ target_id: location._id, parent_id: parentId })
                        }
                        successToast('Beziehung wurde erfolgreich erstellt')
                    } else if (type === 'child') {
                        for (const childId of locationIdArray) {
                            await addChildLocation({ target_id: location._id, child_id: childId })
                        }
                        successToast('Beziehung wurde erfolgreich erstellt')
                    }
                }
                $('#addToLocationTbl').DataTable().destroy();
            })
    }

    const addLocationComment = async (comment) => {
        await addComment({comment: comment, _id: location._id})
    }

    const deleteLocationComment = async (comment_id) => {
        await deleteComment({_id: location._id, comment_id: comment_id})
    }

    const addLocationLog = async ({entry, severity}) => {
        await addLog({entry: entry, severity: severity, _id: location._id})
    }

    const deleteLocationLog = async (log_id) => {
        await deleteLog({log_id: log_id, location_id: location._id})
    }

    const addLocationTag = (newTags) => {
        let tags = [...editForm.tags]
        let _newTags = newTags.split(',').map((v) => v.trim());
        for (let newTag of _newTags) {
            tags.push(newTag)
        }
        tags = tags.filter((v, i, a) => a.indexOf(v) === i && v.trim());
        updateForm('tags', tags)
    }

    const deleteLocationTag =  (index) => {
        let tags = [...editForm.tags];
        tags.splice(index, 1)
        updateForm('tags', tags)
    }

    const updateForm = (key, value, nestedKey) => {
        if (nestedKey) {
            const nested = editForm[nestedKey]
            nested[key] = value
            setEditForm({ ...editForm, [nestedKey]: nested })
        } else {
            setEditForm({ ...editForm, [key]: value })
        }
    }

    const UI = () => {

        const gateways = () => {
            if (location?.gateways !== undefined && location?.gateways.length > 0) {
                return (
                    <div className="col-xl-2 col-md-4">
                        <div className="card text-center">
                            <div className="card-body">
                                <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                    <i className="fa-solid fa-router fa-4x"></i>
                                </div>
                                <p className="text-muted mb-0 line-ellipsis">Gateways</p>
                                <h2 className="mb-0">{location.gateways.length}</h2>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        const renderUviButtons = () => {
            let shouldGetUvi = true
            let shouldDownloadUvi = true
            let buttonTextUvi = 'uVi-Versand'
            let buttonTexDownload = 'uVi-Download'

            if (!location.attributes.includes('uvi_email')) {
                shouldGetUvi = false
                buttonTextUvi = 'kein uVi-Versand'
            }

            if (!location.attributes.includes('uvi_download')) {
                shouldDownloadUvi = false
                buttonTexDownload = 'kein uVi-Download'
            }
            return (
                <div>
                    <button style={{marginRight: "10px"}} className={`btn btn-${shouldDownloadUvi ? 'success' : 'danger'}`} disabled={!user?.roles?.includes('realm_admin') && !user?.roles?.includes('realm_manager') && !user?.roles?.includes('god')} onClick={(e) => { toggleUviAttribute({ shouldDownloadUvi: !shouldDownloadUvi, type: 'download' }) }}>{buttonTexDownload}</button>
                    <button className={`btn btn-${shouldGetUvi ? 'success' : 'danger'}`} disabled={!user?.roles?.includes('realm_admin') && !user?.roles?.includes('realm_manager') && !user?.roles?.includes('god')} onClick={(e) => { toggleUviAttribute({ shouldGetUvi: !shouldGetUvi, type: 'email' }) }}>{buttonTextUvi}</button>
                </div>
            )
        }

        const renderLocationDetails = () => {

            const updateLocation = async () => {
                const doSave = await doYouReallyWantToSave()
                if (!doSave) {
                    return;
                }
                locationUpdate(editForm).finally(setIsEditing(false))
            }

            const RenderConditionalButtons = () => {
                if (isEditing === true) {
                    return (
                        <div className="col-12">
                            <button className="btn btn-primary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => { e.preventDefault(); updateLocation() }}>Speichern</button>
                            <button className="btn btn-muted mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                                e.preventDefault();
                                setIsEditing(false);
                            }}>Abbrechen</button>
                        </div>
                    )
                } else {
                    return (
                        <PermissionsWrapper
                            user={user}
                            scopes={['location.update.any', 'location.update.own']}
                        >
                            <div className="col-12">
                                <button id="locDetailsUpdateBtn" className="btn btn-secondary mt-1 me-1 waves-effect waves-float waves-light" onClick={(e) => {
                                    e.preventDefault();
                                    setEditForm(JSON.parse(JSON.stringify(location)))
                                    setIsEditing(true);
                                }}>Anpassen</button>
                            </div>
                        </PermissionsWrapper>
                    )
                }
                
            }

            const renderBillingMonth = () => {
                const months = [
                    {number: "", name: 'Kein Stichtags-Monat ausgewählt'},
                    {number: "1", name: 'Januar'},
                    {number: "2", name: 'Februar'},
                    {number: "3", name: 'März'},
                    {number: "4", name: 'April'},
                    {number: "5", name: 'Mai'},
                    {number: "6", name: 'Juni'},
                    {number: "7", name: 'Juli'},
                    {number: "8", name: 'August'},
                    {number: "9", name: 'September'},
                    {number: "10", name: 'Oktober'},
                    {number: "11", name: 'November'},
                    {number: "12", name: 'Dezember'}
                ]
                return (
                    <div className="col-12 col-sm-6 mb-1" >
                        <label className="form-label">Stichtags-Monat</label>
                        <select className="select2 form-select" name="billing_month" id="overviewOrganization" onChange={(e) => updateForm('billing_month', e.target.value)} disabled={!isEditing}>
                            {
                                months.map(row => {
                                    return (
                                        <option key={row.number} value={row.number} selected={isEditing ? editForm?.billing_month === row.number : location.billing_month === row.number}>{row.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div >

                )
            }

            return (
                <form className="validate-form mt-2 pt-50" noValidate="noValidate" id="frmLocationDetails">
                    {currTenant.map((eachtnt, index) =>(
                        <div className="row" key={eachtnt.index}>
                            <div className="col-12 col-sm-6 mb-1" >
                                <label className="form-label"> Nutzer</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={(!!eachtnt && !!eachtnt.first_name) ? `${capitalizeUtil.capitalize(eachtnt.first_name)} ${capitalizeUtil.capitalize(eachtnt.last_name)}` : (location.type === 'single_premise') ? 'Leerstand' : ''} disabled={true}/>
                                        <Link to={`/details/tenant/${eachtnt._id}`}>
                                            <a>
                                                <button className="btn btn-primary waves-effect" type="button"><i className="fa-solid fa-user"></i></button>
                                            </a>
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 mb-1">
                                    <label className="form-label">Nutzer Email</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" value={(!!eachtnt  && !!eachtnt.email)? `${eachtnt.email}`:'' } disabled={true} />
                                        <a href={`mailto:${eachtnt.email}`}>
                                            <button className="btn btn-primary waves-effect" type="button"><i className="fa-solid fa-paper-plane-top"></i></button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))

                    }

                    <div className="row">
                        {renderBillingMonth()}
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Name</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.name : capitalizeUtil.capitalize(location?.address?.name)} onChange={(e) => { updateForm('name', e.target.value, 'address') }} disabled={!isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Straße</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.street : capitalizeUtil.capitalize(location?.address?.street)} onChange={(e) => { updateForm('street', e.target.value, 'address') }} disabled={!isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Hausnummer</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.house_number : capitalizeUtil.capitalize(location?.address?.house_number)} onChange={(e) => { updateForm('house_number', e.target.value, 'address') }} disabled={!isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Mieteinheit</label>                                                                                                                                                                               {/* requirement from david: dont let the normal user adjust the ME */}
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.street1 : capitalizeUtil.capitalize(location?.address?.street1)} onChange={(e) => { updateForm('street1', e.target.value, 'address') }} disabled={!user?.roles?.includes('realm_manager') && !user?.roles?.includes('realm_admin') && !user?.roles?.includes('god') ? true : !isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Addresszumsatz 2</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.street2 : capitalizeUtil.capitalize(location?.address?.street2)} onChange={(e) => { updateForm('street2', e.target.value, 'address') }} disabled={!isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Interne Nummer</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.token : capitalizeUtil.capitalize(location?.token)} onChange={(e) => { updateForm('token', e.target.value,) }} disabled={!isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Postleitzahl</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.post_code : capitalizeUtil.capitalize(location?.address?.post_code)} onChange={(e) => { updateForm('post_code', e.target.value, 'address') }} disabled={!isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Stadt</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.city : capitalizeUtil.capitalize(location?.address?.city)} onChange={(e) => { updateForm('city', e.target.value, 'address') }} disabled={!isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Bundesland</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.province : capitalizeUtil.capitalize(location?.address?.province)} onChange={(e) => { updateForm('province', e.target.value, 'address') }} disabled={!isEditing} />
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                            <label className="form-label">Land</label>
                            <input type="text" className="form-control" value={isEditing ? editForm?.address?.country : capitalizeUtil.capitalize(location?.address?.country)} onChange={(e) => { updateForm('country', e.target.value, 'address') }} disabled={!isEditing} />
                        </div>
                    </div>
                    {RenderConditionalButtons()}
                </form>
            )
        }

        if( !!location && location.address === undefined ) {
            return <Fragment></Fragment>
        } else {
            return (
                <Fragment>

                    <div className="content-header row">
                        <div className="content-header-left col-md-9 col-12 mb-2">
                            <div className="row breadcrumbs-top">
                                <div className="col-9">
                                    <h2 className="content-header-title float-start mb-0">Nutzer-/Mieteinheits-Details </h2>
                                    <div className="breadcrumb-wrapper">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Apps</a></li>
                                            <li className="breadcrumb-item"><Link className="breadcrumb-navLink" to="/apps/locations"><a>Nutzereinheiten</a></Link></li>
                                            <li className="breadcrumb-item active">Details
                                            </li>
                                            <li>
                                                <span className="tourBorderRight"></span>
                                                <PageTour steps={TourSteps.Locations.details}  isButton={false} />
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OrgCookieCrumb />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card" id="currentLoc">
                                <div className="card-header border-bottom">
                                    <h4 className="card-title">
                                        <span className="me-1" 
                                            dangerouslySetInnerHTML={{__html:locationIcon({location, size:1})}}>
                                        </span> 
                                        {
                                            `${capitalizeUtil.capitalize(location.address.street)} ${location.address.house_number}, ${(location.address.street1 !== '') ? `ME ${location.address.street1}, ` : ''}${location.address.post_code} ${capitalizeUtil.capitalize(location.address.city)}, ${location.address.country.toLocaleUpperCase()}`
                                        }
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">

                        <section id="consumption">
                            <div className="row match-height" id="locConsumptionChart">
                                <ConsumptionChart 
                                    location={location}
                                    height="250"
                                />
                            </div>
                        </section>

                        <section id="locationData">

                            <div className="row match-height">
                                <div className="col-xl-2 col-md-4" id="locDetailsSensorsCounter">
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                                <i className="fa-solid fa-4x fa-microchip"></i>
                                            </div>
                                            <p className="text-muted mb-0 line-ellipsis">Zähler</p>
                                            <h2 className="mb-0">{(location?.sensors !== undefined) ? location?.sensors.length : 0}</h2>
                                        </div>
                                    </div>
                                </div>

                                <PermissionsWrapper user={user} scopes={['gateway.view.any', 'gateway.view.own']}>
                                    <div className="col-xl-2 col-md-4" id="locDetailsGateWaysCounter">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                                    <i className="fa-solid fa-router fa-4x"></i>
                                                </div>
                                                <p className="text-muted mb-0 line-ellipsis">Gateways</p>
                                                <h2 className="mb-0">{(location?.gateways) ? location?.gateways.length : 0}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </PermissionsWrapper>

                               <PermissionsWrapper user={user} scopes={['tenant.view.own', 'tenant.view.any']}>
                                    <div className="col-xl-2 col-md-4" id="locDetailsTenantsCounter">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                                    <i className="fa-solid fa-people-roof fa-4x"></i>
                                                </div>
                                                <p className="text-muted mb-0 line-ellipsis">Nutzer</p>
                                                <h2 className="mb-0">{(currTenant !== undefined) ? currTenant?.length : 0}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </PermissionsWrapper>

                               
                                <div className="col-xl-2 col-md-4" id="locDetailsUsersCounter">
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <div className="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                                                <i className="fa-solid fa-user fa-4x"></i>
                                            </div>
                                            <p className="text-muted mb-0 line-ellipsis">Benutzer</p>
                                            <h2 className="mb-0">{(location?.users !== undefined) ? location?.users.length : 0}</h2>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                        <section id="locationTabs">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-pills mb-2">

                                        <li className="nav-item" id="LocDetailTb">
                                            <a className={`nav-link ${(tabActive === 'details') ? 'active' : ''}`} onClick={() => { setTabActive('details') }}>
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                                <span className="fw-bold">Details</span>
                                            </a>
                                        </li>

                                        <PermissionsWrapper
                                            user={user}
                                            roles={['realm_admin', 'realm_manager']}
                                        >
                                            <li className="nav-item" id="LocRelationshipsTb">
                                                <a className={`nav-link ${(tabActive === 'relationships') ? 'active' : ''}`} onClick={() => { setTabActive('relationships') }}>
                                                    <i className="fa-solid fa-link"></i>
                                                    <span className="fw-bold">Beziehungen</span>
                                                </a>
                                            </li>
                                        </PermissionsWrapper>

                                        <PermissionsWrapper
                                            user={user}
                                            scopes={['sensor.view.any', 'sensor.view.own']}
                                        >
                                            <li className={`nav-item ${(sensors.length === 0) ? 'hidden' : ''}`} id="LocSensorsTb">
                                                <a className={`nav-link ${(tabActive === 'sensors') ? 'active' : ''}`} onClick={() => { setTabActive('sensors') }}>
                                                    <i className="fa-solid fa-microchip"></i>
                                                    <span className="fw-bold">Zähler</span>
                                                </a>
                                            </li>
                                        </PermissionsWrapper>
 
                                        <PermissionsWrapper
                                            user={user}
                                            scopes={['gateway.view.any', 'gateway.view.own']}
                                        >
                                            <li className={`nav-item ${(location?.gateways?.length === 0) ? 'hidden' : ''}`} id="LocGatewaysTb">
                                                <a className={`nav-link ${(tabActive === 'gateways') ? 'active' : ''}`} onClick={() => { setTabActive('gateways') }}>
                                                    <i className="fa-solid fa-router" />
                                                    <span className="fw-bold">Gateways</span>
                                                </a>
                                            </li>
                                        </PermissionsWrapper>

                                        <PermissionsWrapper
                                            user={user}
                                            roles={['realm_admin', 'realm_manager']}
                                        >
                                            <li className="nav-item" id="LocCommentsTb">
                                                <a className={`nav-link ${(tabActive === 'comments') ? 'active' : ''}`} onClick={() => { setTabActive('comments') }}>
                                                    <i className="fa-solid fa-message-lines"></i>
                                                    <span className="fw-bold">Anmerkungen</span>
                                                </a>
                                            </li>
                                        </PermissionsWrapper>

                                        <PermissionsWrapper
                                            user={user}
                                            roles={['realm_admin', 'realm_manager']}
                                        >
                                            <li className="nav-item" id="LocUsersTb">
                                                <a className={`nav-link ${(tabActive === 'users') ? 'active' : ''}`} onClick={() => { setTabActive('users') }}>
                                                    <i className="fa-solid fa-user"></i>
                                                    <span className="fw-bold">Benutzer</span>
                                                </a>
                                            </li>
                                        </PermissionsWrapper>

                                    </ul>
                                </div>
                            </div>
                        </section>

                        <section id="tabpanels">
                            <div className="row">
                                <div className="col-12">
                                    <div className="tab-content pt-1">

                                        <div className={`tab-pane ${(tabActive === 'details') ? 'active' : ''}`} id="details" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <div className="card">
                                                <div className="card-header border-bottom">
                                                    <h4 className="card-title">Details der Nutzer-/Mieteinheit   </h4>

                                                    <PermissionsWrapper
                                                        user={user}
                                                        roles={['realm_admin', "realm_manager"]}
                                                    >
                                                        {renderUviButtons()}
                                                    </PermissionsWrapper>

                                                </div>

                                                <PermissionsWrapper
                                                    user={user}
                                                    scopes={['tag.view.any', 'tag.view.own']}
                                                >
                                                 <span id="currLocTags">
                                                    <TagComponent tags={(isEditing ? editForm.tags : location.tags)} isEditing={isEditing} addTagFunction={addLocationTag} deleteTagFunction={deleteLocationTag} />
                                                </span>
                                                </PermissionsWrapper>
                                                
                                                <div className="card-body" id="locDetailsBody">
                                                    {renderLocationDetails()}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`tab-pane ${(tabActive === 'relationships') ? 'active' : ''}`} id="relationships" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <div className="card">

                                                <div className="card-header border-bottom">
                                                    <div className='row w-100'>
                                                        <h4 className="card-title col-12">
                                                            Beziehungen

                                                            <PermissionsWrapper
                                                                user={user}
                                                                scopes={['location.update.any', 'location.update.own']}
                                                            >
                                                                <button className='btn btn-success float-end ms-1' disabled={location.type === 'single_premise' ? true : false} onClick={() => { createRelationLocation('child') }}>
                                                                    <i className="fa-solid fa-plus"></i> Untergeordnete Mieteinheit
                                                                </button>
                                                                <button className='btn btn-success float-end' disabled={location.type === 'multi_premise' || location?.parents?.length !== 0 ? true : false} onClick={() => { createRelationLocation('parent') }}>
                                                                    <i className="fa-solid fa-plus"></i> Übergeordnete Nutzereinheit
                                                                </button>
                                                            </PermissionsWrapper>

                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="card-body py-2 my-25">
                                                    <div className="row">
                                                        <div className="col-12">

                                                        {locationParents.length > 0 ?
                                                            <LocationsTable
                                                                id="tblLocationParents"
                                                                locations={locationParents}
                                                                type="parents"
                                                            />
                                                            : <span id="tblLocationParentsEmpty"> </span>
                                                        }

                                                        {locationChildren.length > 0 ?
                                                            <LocationsTable
                                                                id="tblLocationChildren"
                                                                locations={locationChildren}
                                                                type="children"
                                                            />
                                                            : <span id="tblLocationChildrenEmpty"> </span>
                                                        }
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`tab-pane ${(tabActive === 'sensors') ? 'active' : ''}`} id="logs" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <div className="card">
                                                <div className="card-header border-bottom">
                                                    <h4 className="card-title">Zähler </h4>
                                                </div>
                                                <div className="card-body py-2 my-25">
                                                    <SensorsTable 
                                                        id="tblLocationSensors"
                                                        sensors={locationSensors} 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`tab-pane ${(tabActive === 'gateways') ? 'active' : ''}`} id="gateways" role="tabpanel" aria-labelledby="home-tab-fill">
                                            <div className="card">
                                                <div className="card-header border-bottom">
                                                    <h4 className="card-title">Gateways </h4>
                                                </div>
                                                <div className="card-body py-2 my-25">
                                                    <GatewaysTable 
                                                        location={location}
                                                        gateways={gateways} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <PermissionsWrapper
                                                user={user}
                                                scopes={['comment.view.any', 'comment.view.own']}
                                            >
                                            <div className={`tab-pane ${(tabActive === 'comments') ? 'active' : ''}`} id="comments" role="tabpanel" aria-labelledby="home-tab-fill">
                                                <CommentTable comments={location.comments} addComment={addLocationComment} deleteComment={deleteLocationComment} updateState={setLocation} />
                                            </div>
                                        </PermissionsWrapper>

                                        <PermissionsWrapper
                                            user={user}
                                            scopes={['user.view.any', 'user.view.own']}
                                        >
                                            
                                            <div className={`tab-pane ${(tabActive === 'users') ? 'active' : ''}`} id="users" role="tabpanel" aria-labelledby="home-tab-fill">
                                                <div className="card">
                                                    <div className="card-header border-bottom text-end">
                                                        <h4 className="card-title">Benutzer </h4>

                                                       <PermissionsWrapper
                                                            user={user}
                                                            scopes={['user.invite.any', 'user.invite.own']}
                                                        >
                                                            <div>
                                                                <button className="btn btn-primary" title="Liegenschaftvervalter Benutzer hinfügen" onClick={addBldgManager}>
                                                                    <i className="fa-solid fa-1x fa-user-pilot-tie"></i>
                                                                </button>
                                                            </div>
                                                        </PermissionsWrapper>

                                                    </div>
                                                    <div className="card-body py-2 my-25">
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                               <LocationUsersTable users={locationUsers} removeUser={removeUser} user={user} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </PermissionsWrapper>

                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>

                </Fragment>

            )
        }

    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )




}


LocationDetails.requiredScope = ['location.view.any', 'location.view.own', 'group.view.own', 'group.view.any']
LocationDetails.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default LocationDetails;