import React, {Fragment, useEffect, useContext } from 'react';
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { CSVLink, CSVDownload } from "react-csv";
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import { PermissionsWrapper } from '../../reactPermissionsWrapper';

const CountHomelessSensorsWidget = (props) => {

    const { sensors, sensorsChanged, sanitizedSensor } = useContext(SensorsContext);
    const {user} = useContext(AuthContext)

    useEffect(  () => {
            
    }, [sensors, sensorsChanged ]);

    

    const UI = () => {
        let homeless = [];
        if(!!sensors && sensors.length > 0) homeless = sensors.filter(sensor => {
            if(!sensor.location_id || sensor.location_id === '') return sanitizedSensor(sensor)
        })
        if(homeless.length > 0){
            return (
                <Fragment>

                    <div className="col-xl-2 col-md-4" id="widgetSensorshomelessCount">

                        <div className="card text-center " title="Anzahl an Zählern, die keiner Nutzer-/Mieteinheit zugeordnet sind">
                            <PermissionsWrapper
                                user={user}
                                roles={['realm_admin', 'realm_manager']}
                            >
                                <CSVLink
                                    data={homeless}
                                    filename={"sensoren_nicht_zugewiesen.csv"}
                                >
                                    <span className="badge rounded-pill bg-info fa-fade badge-up"><i class="fa-solid fa-download"></i></span>
                                </CSVLink>
                            </PermissionsWrapper>
                            <div className="card-body align-self-center">
                                <div className="mx-auto my-1" style={{ display: "inline-flex", alignItems: "center" }}>
                                    <i className={`fa-solid fa-house-circle-exclamation fa-4x text-danger`}></i>
                                </div>
                                <p className="mb-0 text-muted">Nicht zugewiesen</p>
                                <h2 className="mb-0 line-ellipsis ">{homeless.length}</h2>
                            </div>
                        </div>
                    </div>

                </Fragment>

            )
        } else {
            return (<Fragment></Fragment>)
        }
        
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountHomelessSensorsWidget;