import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import Swal from '@molline/sweetalert2';
import {useRouter} from "../../../../components/Shared/Router/Router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import  {faPenToSquare, faPlus} from "@fortawesome/free-solid-svg-icons";
import TblOrganizations from "../../../../components/Dashboard/tables/dataTables/tblOrganizations";
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";


const AppsOrganizatoins = (props) => {

    const { user } = useContext(AuthContext);
    const { organizations, setOrganizations, createOrganization, deleteOrganization } = useContext(OrganizationContext);
    const router = useRouter()


    useEffect(() => {

    }, [organizations]);

    const deleteMyOrganization = async (organization_id) => {
        const doSave = await doYouReallyWantToSave()
        if (!doSave) {
            return
        }
        try {
            await deleteOrganization(organization_id)
            successToast('Organisation wurde erfolgreich gelöscht')
        } catch (e) {
            console.log('error at deleting organization', e);
            errorToast('Beim Löschen der Organisation ist ein Fehler aufgetreten!')
        }
    }

    const errorToast = (err) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: err,
            showConfirmButton: false,
            customClass: {
                popup: "bg-danger"
            }
        })
    }

    const successToast = (msg) => {
        Swal.fire({
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: true,
            text: msg,
            showConfirmButton: false,
            customClass: {
                popup: "bg-success"
            }
        })
    }

    const doYouReallyWantToSave = () => {
        return new Promise(async (resolve) => {
            if (window.sessionStorage && !window.sessionStorage.getItem("yeswecan")) {
                const answer = await Swal.fire({
                    title: 'Sicher?',
                    input: 'checkbox',
                    text: 'Damit werden die Daten gespeichert',
                    inputPlaceholder: 'Nicht mehr fragen',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: "Nein",
                })
                if (!answer.isConfirmed)
                    return resolve(false);
                if (answer.value === 1) {
                    window.sessionStorage.setItem("yeswecan", true);
                    return resolve(true);
                }
            }
            return resolve(true);
        })
    }

    const UI = () => {

        return (
            <Fragment>

                <div className="content-header row">
                    <div className="content-header-left col-md-9 col-12 mb-2">
                        <div className="row breadcrumbs-top">
                            <div className="col-9 ">
                                <h2 className="content-header-title float-start mb-0">Organisationen</h2>
                                <div className="breadcrumb-wrapper">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a>Verwaltung</a></li>
                                        <li className="breadcrumb-item active">Organisationen
                                        </li>
                                        <li>
                                            <span className="tourBorderRight" />
                                            <PageTour steps={TourSteps.organizations.overview} isButton={false} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrgCookieCrumb />
                </div>


                <div className="content-body">
                    <section id="tabpanels">
                        <div className="row">
                            <div className="col-12">
                                <div class="tab-content pt-1">


                                    <div className="card">
                                        <div  className="card-header border-bottom">
                                            <h4 className="card-title">Organisationen verwalten   </h4>
                                            <div className="heading-elements">
                                                <div className="heading-elements">
                                                    <PermissionsWrapper scopes={['organization.create.any', 'organization.create.own']}  roles={['god']} user={user}>
                                                        <button className='btn btn-primary' id="orgCreateBtn" onClick={() => { router.push('/admin/organizations/create') }}><FontAwesomeIcon icon={faPlus} /></button>
                                                    </PermissionsWrapper>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-body py-2 my-25">

                                            <TblOrganizations organizations={organizations} deleteOrganization={deleteMyOrganization} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </Fragment>
        )
    }

    return (
        <Fragment>
            {UI()}


        </Fragment>

    )


}

AppsOrganizatoins.requiredRole = ['realm_manager', 'realm_admin'];
AppsOrganizatoins.requireAuth = true;  // true -> force login via AuthContext, false -> no login required so no user available either
export default AppsOrganizatoins;