import React, {Fragment, useEffect, useContext, useState } from 'react';
import {SensorsContext} from "../../../../context/Devices/Sensors";
import { CSVLink, CSVDownload } from "react-csv";
import { AuthContext } from '../../../../context/Auth/AuthProvider';
import { PermissionsWrapper } from '../../reactPermissionsWrapper';

const CountWmbusSensorsWidget = (props) => {

    const { sensors, sensorsChanged, sanitizedSensor } = useContext(SensorsContext);
    const {user} = useContext(AuthContext)

    useEffect(  () => {

            
    }, [sensors, sensorsChanged ]);

    const UI = () => {
        let sensorArray = [];
        let totalWmbusCount = 0;
        if(!!sensors && sensors.length > 0){
            sensorArray = sensors.filter(sensor => {
                if(sensor.attributes.includes('wmbus')) return sanitizedSensor(sensor) 
            })


            let date = new Date()
            let wmbus = sensors.filter(sensor => sensor.attributes.includes('wmbus'));

            for(let sensor of wmbus){
                let sDate = new Date(sensor.state.date)
                let hours = Math.abs(date - sDate) / 36e5;
                if(hours < 360){
                    totalWmbusCount ++
                }
            }
        }
        
        if(sensorArray.length > 0){
            return (
                <div className="col-xl-2 col-md-4">

                    <div className="card text-center " title="Anzahl der WMBUS-Zähler" id="widgetCountWmbusSensors">
                        <PermissionsWrapper
                            user={user}
                            roles={['realm_admin', 'realm_manager']}
                        >
                            <CSVLink
                                data={sensorArray}
                                filename={"mbus-sensor-report.csv"}
                            >
                                <span className="badge rounded-pill bg-info fa-fade badge-up" id="funkSensorsDownloadIcon"><i class="fa-solid fa-download"></i></span>
                            </CSVLink>
                        </PermissionsWrapper>
                        <div className="card-body align-self-center">
                            <div className="mx-auto my-1" style={{ display: "inline-flex", alignItems: "center" }}>
                                <i className={`fa-solid fa-wifi fa-4x text-success`}></i>
                            </div>
                            <p className="mb-0 text-muted">Funk-Zähler empfangen</p>
                            <h2 className="mb-0 line-ellipsis ">{`${totalWmbusCount}/${sensorArray.length}`}</h2>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <Fragment></Fragment>

        )
        
    }

    return (
        <Fragment>
            {UI()}
        </Fragment>
    )

}

export default CountWmbusSensorsWidget;