import React, {Fragment, useState, useEffect, useContext, useRef} from 'react';
import {AuthContext} from "../../../../context/Auth/AuthProvider";
import {OrganizationContext} from '../../../../context/Organization/Organization';
import Axios_client from "../../../../utils/axios_client";
import FormData from 'form-data'
import {PermissionsWrapper, hasPermission} from "../../../../components/Dashboard/reactPermissionsWrapper";
import OrgCookieCrumb from "../../../../components/Dashboard/orgCookieCrumb";
import { Buffer } from "buffer";
import Swal from '@molline/sweetalert2';
import Dropzone from 'react-dropzone';
import { SocketContext } from '../../../../context/Socket/Sockets';

import LinearProgressBar from '../../../../components/Dashboard/progress/bar_linear';
import TourSteps from "../../../../TourSteps.json";
import PageTour from "../../../../components/Dashboard/tour/tour";

const DataManager2 = () => {

  const {user} = useContext(AuthContext);
  const { socket, socketId } = useContext(SocketContext);
  const {organizations, organization} = useContext(OrganizationContext);
  const [updated, setUpdated] = useState(false)
  /**
   * UI
   */
  const [tabActive, setTabActive] = useState('import-tab');
  /**
   * OPERATIONAL CONSTANTS
   */
  const [fileName, setFileName] = useState(null);

  const [taskList, setTaskList] = useState(
      {
          locations:0,
          sensors:0,
          gateways:0
      }
  )
  const [completed, setCompleted] = useState(
      {
          locations:0,
          sensors:0,
          gateways:0
      }
  )
  const [removed, setRemoved] = useState(
      {
          locations:0,
          sensors:0,
          gateways:0
      }
  )
  const [errors, setErrors] = useState([])
  const [warnings, setWarnings] = useState([])
  const [tasksPresent, setTasksPresent] = useState(0)
  const organizationChanged = useCompare(organization)
  const [working, setWorking] = useState(false)
  const [progress, setProgress] = useState(0);
  const [socketLocationMessage, setSocketLocationMessage] = useState('');
  const [socketSensorMessage, setSocketSensorMessage] = useState('');
  const [socketGatewayMessage, setSocketGatewayMessage] = useState('');

  useEffect(() => {
      if(!updated){
          setProgress(0)
          setUpdated(true)
      }

      if(socket !== null ){
          socket.on('datamanager', (data) => {
              if(data.payload.socket_id === socketId){
                  switch(data.event){
                      case "location.create":
                          setSocketLocationMessage(`${data.payload.message}`)
                          break;
                      case "sensor.create":
                          setSocketSensorMessage(` -> ${data.payload.message}`)
                          break;
                      case "gateway.create":
                          setSocketGatewayMessage(` -> ${data.payload.message}`)
                          break;
                      case "progress":
                          if(+data.payload.percent === 100){
                              setSocketLocationMessage('');
                              setSocketSensorMessage('');
                              setSocketGatewayMessage('');
                          }
                          setProgress( (+data.payload.percent).toFixed(1) )
                          break;
                      case "cleanup":
                          break;
                      case "complete":
                          completeThis( data.payload.message )
                          break;
                      default:
                          break;
                  }
              }
          })
      }
  },[user, tabActive, organization, fileName, errors, warnings, organization, taskList,completed,removed, updated, organizationChanged, organizations, progress])

  function useCompare(val) {
      const prevVal = usePrevious(val)
      return prevVal !== val
  }

  function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
          ref.current = value;
      });
      return ref.current;
  }

  const upload = async (acceptedFiles) => {

      if(!organization){
          Swal.fire({
              title: "FEHLER",
              text: 'Bitte wählen Sie zuerst eine Organisation aus.',
              icon: "error",
              showConfirmButton: true,
          })
          return
      }
      const accepted = ['text/csv']
      let errorArray = []
      if(acceptedFiles.length > 1){
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Bitte nur EINE Datei hochladen',
          })
          return;
      }
      if(!accepted.includes(acceptedFiles[0].type)){
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Es sind nur CSV-Dateien erlaubt',
          })
          return;
      }

      let formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      formData.append("organization_id", organization._id.toString());

      Axios_client.backendClient().post(`${process.env.REACT_APP_DATAMANAGER}/validate`, formData, {
          headers: {
          // 'Content-Type': `multipart/form-data`,
          },
          maxBodyLength: Infinity,
          maxContentLength: Infinity,
          timeout: 10000,
      })
      .then( results => {
          //console.log('RESULTS RETURNED',results.data)
          setErrors(()=>[...results.data.tasklist.errors]);
          setWarnings(()=>[...results.data.tasklist.warnings])
          setFileName(results.data.file_name)
          let newTaskList = {...taskList}
          newTaskList.locations = results.data.tasklist.locations
          newTaskList.sensors = results.data.tasklist.sensors
          newTaskList.gateways = results.data.tasklist.gateways
          setTaskList(()=>new Object({...newTaskList}));
          setTasksPresent(Object.keys(newTaskList).length)

      })
      .catch(err => {
          Swal.fire({
              title: "FEHLER",
              text: err.message,
              icon: "error",
              showConfirmButton: true,
          })
      });
  }

  const exportData = () => {
      let orgName, fileName, buffer, blob, fileURL, link;
      Swal.fire({
          title: "Export",
          text: `Hiermit werden Sie alle Daten des Kunden ${organization.name} als CSV-Datei herunterladen. Die Daten können dann angepasst und erneut hochgeladen werden`,
          icon: "info",
          showConfirmButton: true,
      })
      .then(results => {
          if(results.isConfirmed){
              Axios_client.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/datamanager/export?organization_id=${organization._id}`)
              .then((response) => {

                  orgName = organization.name.replaceAll(' ','_');
                  orgName.trim()

                  fileName = `${new Date().getTime()}_${orgName}.csv`
                  buffer = Buffer.from(response.data, "utf-8")

                  console.log(buffer)

                  blob = new Blob([buffer], { type: 'text/csv' })
                  fileURL = URL.createObjectURL(blob);
                  link = document.createElement('a');
                  link.href = fileURL;
                  link.setAttribute('download', fileName);
                  link.setAttribute('target', '_blank');
                  document.body.appendChild(link);
                  link.dispatchEvent(
                      new MouseEvent('click', {
                          bubbles: true,
                          cancelable: true,
                          view: window
                      })
                  );
                  link.remove();
              })
              .catch( err => {
                  Swal.fire({
                      title: "FEHLER",
                      text: err.message,
                      icon: "error",
                      showConfirmButton: true
                  })
              })
          }
      })
  }

  const completeThis = (report) => {
      setWorking(false)
      let newTaskList = {...taskList}
      newTaskList.locations = report.tasklist.locations
      newTaskList.sensors = report.tasklist.sensors
      newTaskList.gateways = report.tasklist.gateways
      setTaskList(()=>new Object({...newTaskList}));

      let updated = {...completed}
      updated.locations = report.completed.locations
      updated.sensors = report.completed.sensors
      updated.gateways = report.completed.gateways
      setCompleted(updated);

      let resultsRemoved = {...removed}
      resultsRemoved.locations = report.removed.locations
      resultsRemoved.sensors = report.removed.sensors
      resultsRemoved.gateways = report.removed.gateways
      setRemoved(resultsRemoved);

      let importErrors = []
      let importWarnings = []
      report.completed.errors.map(err => {importErrors.push(err)})
      report.completed.warnings.map(err => {importWarnings.push(err)})
      report.removed.errors.map(err => {importErrors.push(err)})
      report.removed.warnings.map(err => {importWarnings.push(err)})

      setErrors(importErrors);
      setWarnings(importWarnings);
      setFileName(null);

      Swal.fire({
          title: "Erfolg",
          text: `Daten für ${organization.name} wurden importiert. Bitte korrigieren Sie die Import-Datei, falls Fehler angezeigt werden und laden Sie diese dann erneut hoch.`,
          icon: "success",
          showConfirmButton: true
      })
      .then(async (results) => {
          
      })
  }

  const ImportData = () => {
      setWorking(true)
      setErrors([])
      setWarnings([])
      setProgress(0)

      Axios_client.backendClient().post(`${process.env.REACT_APP_API_OPENMETER}/datamanager/import`, {
          "organization_id": organization._id,
          "file_name": fileName,
          "socket_id": socketId,
          "count_tasks": ( taskList.locations + taskList.sensors + taskList.gateways  ).toString()
      })
      .then((results) => {
          Swal.fire({
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: true,
              text: 'Ihr Import ist im Gange',
              showConfirmButton: false,
              customClass: {
                  popup: "bg-success text-white"
              }
          })
      })
      .catch( err => {
          console.log(err)
          setWorking(false)
          Swal.fire({
              title: "FEHLER",
              text: err,
              icon: "error",
              showConfirmButton: true
          })
          
      })
  }

  const UI = () => {
      
      return (
          <Fragment>
              <div className="content-header row">
                  <div className="content-header-left col-md-9 col-12 mb-2">
                      <div className="row breadcrumbs-top">
                          <div className="col-12">
                              <h2 className="content-header-title float-start mb-0">Data Manager v2.0.0</h2>
                              <div className="breadcrumb-wrapper">
                                  <ol className="breadcrumb">
                                      <li className="breadcrumb-item">Tools</li>
                                      <li className="breadcrumb-item active">Daten-Import
                                      </li>
                                      <li>
                                          <span className="tourBorderRight" />
                                          <PageTour steps={TourSteps.data_manager.overview}  isButton={false} />
                                      </li>
                                  </ol>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="content-body" >

                  <section id="dataManagerTabs mt2">
                      <div className="col-12">
                          <ul className="nav nav-pills mb-2">
                              <PermissionsWrapper
                                  user={user}
                                  scopes={['location.create.any','sensor.create.any','gateway.create.any']}
                              >
                              <li className="nav-item" >
                                  <a className={`nav-link ${(tabActive === 'import-tab') ? 'active' : ''}`} onClick={() => { setTabActive('import-tab') }}>
                                      <i class="fa-solid fa-download"></i>
                                      <span className="fw-bold">Import</span>
                                  </a>
                              </li>
                              </PermissionsWrapper>

                              <li className="nav-item" id="dataManagerExportTab">
                                  <a className={`nav-link ${(tabActive === 'export-tab') ? 'active' : ''}`} onClick={() => { setTabActive('export-tab') }}>
                                      <i class="fa-solid fa-upload"></i>
                                      <span className="fw-bold">Export</span>
                                  </a>
                              </li>
                          </ul>
                      </div>
                  </section>

                  <section id="dataManagerTabContent">
                      <div className="row">
                          <div className="col-12">
                              <div className="tab-content pt-1">

                                  <div className={`tab-pane ${(tabActive === 'import-tab') ? 'active' : ''}`} id="import-tab" role="tabpanel" aria-labelledby="import-tab-fill">
                                      <div className="card" id="dataManagerImportTab">
                                          <div className="card-header border-bottom">
                                              <h4 className="card-title">Daten importieren {(organization.name)? `-> ${organization.name}`: ""}  </h4>
                                          </div>
                                          <div className="card-body">
                                              <LinearProgressBar
                                                  id="upload-progress"
                                                  value={progress}
                                                  height="2"
                                              />
                                              <div>{socketLocationMessage}{socketSensorMessage}{socketGatewayMessage}</div>
                                          </div>

                                          {tasksPresent > 0 &&
                                          <div className={`card-body`}>
                                              <div className="content-header mt-1">
                                                  <h5 className="mb-1">Aufgaben-Liste</h5>

                                                  <p className="">
                                                      {`
                                                          ${(errors.length === 0)? "Sehr gut. Alles sieht gut aus. Wenn alle Felder grün sind, können Sie den Import starten. Viel Glück!":"Leider sind Probleme bei der Überprüfung aufgetreten. Bitte beheben Sie die unten stehenden Probleme in den genannten Zeilen und starten Sie den Import erneut."}
                                                      `}
                                                  </p>
                                                  <button className={`btn btn-${(errors.length > 0 || !fileName)?"danger":"success"} mb-2`} disabled={(errors.length === 0 && !!fileName)?"":"true"} onClick={() =>{ImportData()}}>
                                                      IMPORT: {fileName } <span className={`ms-1 spinner-border spinner-border-sm ${(working)? '':'hidden'}`} role="status" aria-hidden="true"></span> 
                                                  </button>

                                                  <section className={`mb-2 ${ (errors.length > 0)? "":"hidden" }`} id="upload-errors">
                                                      <h4 className="text-danger"><b>FEHLER</b></h4>
                                                      <table className="table table-hover">
                                                          <thead>
                                                              <tr>
                                                                  <th>Zeile</th>
                                                                  <th>Spalte</th>
                                                                  <th>Fehler</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              {
                                                                  errors.map(err => {

                                                                      console.log(err)

                                                                      let cols = []
                                                                      let descrips = []
                                                                      
                                                                      Object.keys(err.error).map(col => {
                                                                          cols.push(<div className="mb-1"><span className="badge badge-danger badge-pill"> {col} </span></div>)
                                                                          err.error[col].map(descrip => {
                                                                              descrips.push(<div className="mb-1">{descrip}</div>)
                                                                          })
                                                                      })

                                                                      return (
                                                                          <tr>
                                                                              <td className="table-danger">{err.row}</td>
                                                                              <td>
                                                                                  {cols}
                                                                              </td>
                                                                              <td>{descrips}</td>
                                                                          </tr>
                                                                      )
                                                                  })
                                                              }
                                                          </tbody>
                                                          <tfoot></tfoot>
                                                      </table>
                                                  </section>

                                                  <section className={`mb-2 ${ (warnings.length > 0)? "":"hidden" }`} id="upload-warnings">
                                                      <h4 className="text-warning"><b>Warnungen</b></h4>
                                                      <table className="table table-hover">
                                                          <thead>
                                                              <tr>
                                                                  <th>Zeile</th>
                                                                  <th>Spalte</th>
                                                                  <th>Warnungen</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              {
                                                                  warnings.map(warn => {

                                                                      let cols = []
                                                                      let descrips = []
                                                                      
                                                                      Object.keys(warn.warning).map(col => {
                                                                          cols.push(<div className="mb-1"><span className="badge badge-warning badge-pill"> {col} </span></div>)
                                                                          warn.warning[col].map(descrip => {
                                                                              descrips.push(<div className="mb-1">{descrip}</div>)
                                                                          })
                                                                      })

                                                                      return (
                                                                          <tr>
                                                                              <td className="table-warning">{warn.row}</td>
                                                                              <td>
                                                                                  {cols}
                                                                              </td>
                                                                              <td>{descrips}</td>
                                                                          </tr>
                                                                      )
                                                                  })
                                                              }
                                                          </tbody>
                                                          <tfoot></tfoot>
                                                      </table>
                                                  </section>

                                                  <section id="upload-status">
                                                      <h4 className="text-primary"><b>Fortschritt</b></h4>
                                                      <table className="table b-table table-hover b-table-caption-top">
                                                          <thead>
                                                              <tr>
                                                                  <th>Art</th>
                                                                  <th>ToDo</th>
                                                                  <th>Aktualisiert</th>
                                                                  <th>Entfernt</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              <tr>
                                                                  <th>Nutzereinheiten</th>
                                                                  <td className={`${ (taskList.locations > 0)? "table-success" : "table-secondary" }`}>{taskList.locations}</td>
                                                                  <td className={`${ (completed.locations === taskList.locations) ? "table-success" : "table-secondary" }`}>{completed.locations}</td>
                                                                  <td className={`${ (removed.locations > 0) ? "table-success" : "table-secondary" }` }>{removed.locations}</td>
                                                              </tr>
                                                              <tr>
                                                                  <th>Zähler</th>
                                                                  <td className={`${ (taskList.sensors > 0) ? "table-success" : "table-secondary" }`}>{taskList.sensors}</td>
                                                                  <td className={`${ (completed.sensors === taskList.sensors) ? "table-success" : "table-secondary" }`}>{completed.sensors}</td>
                                                                  <td className={`${ (removed.sensors > 0) ? "table-success" : "table-secondary" }`}>{removed.sensors}</td>
                                                              </tr>
                                                              <tr>
                                                                  <th>Gateways</th>
                                                                  <td className={`${ (taskList.gateways > 0) ? "table-success" : "table-secondary" }`}>{taskList.gateways}</td>
                                                                  <td className={`${ (completed.gateways === taskList.gateways) ? "table-success" : "table-secondary" }`}>{completed.gateways}</td>
                                                                  <td className={`${ (removed.gateways > 0) ? "table-success" : "table-secondary" }`}>{removed.gateways}</td>
                                                              </tr>
                                                          </tbody>
                                                          <tfoot></tfoot>
                                                      </table>
                                                  </section>
                                                  
                                              </div>
                                          </div>
                                          }
                                          { !tasksPresent &&
                                          <div className={`card-body`}>
                                              
                                              <div className="content-header mt-2">
                                                  <h5 className="mb-1">CSV Validator</h5>
                                                  <p className="">Laden Sie zuerst Ihre CSV-Datei hoch. Der Validator wird alle Felder der Datei auf Fehler überprüfen und Ihnen im Anschluss die Ergebnisse anzeigen. </p>
                                                  <p>
                                                      <ul>
                                                          <li><b className="text-danger">FEHLER:</b> Sollten Fehler vorhanden sein, so wird der Validator die Zeile und den dazugehörigen Datensatz anzeigen. Teilweise wird Ihnen der Validator auch sagen,
                                                                wie Sie den Fehler beheben können. Sollten keine Fehler vorhanden sein, dann wird der Validator den Import-Prozess beenden und Ihnen eine Aufgabenliste anzeigen.
                                                          </li>
                                                          <li>
                                                              <b className="text-warning">WARNUNGEN:</b> Sollte es Warnungen geben, können diese ignoriert werden, da der Import trotzdem funktioniert. Prüfen Sie jedoch diese und beheben sie die Probleme, falls nötig.
                                                              Danach können Sie den Import starten.
                                                          </li>
                                                      </ul>
                                                  </p>
                                              </div>

                                              <div className="row mt-2">
                                                  <div className="mb-1 col-md-12">
                                                      <div className="dropzone dropzone-area ">
                                                          <Dropzone onDrop={acceptedFiles => upload(acceptedFiles)}>
                                                              {({getRootProps, getInputProps}) => (
                                                                  <section className="bg-light-error">
                                                                  <div {...getRootProps()}>
                                                                      <input {...getInputProps()} />
                                                                      <p className="dz-message">CSV hier ablegen oder klicken, um eine Datei auszuwählen</p>
                                                                  </div>
                                                                  </section>
                                                              )}
                                                          </Dropzone>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          }
                                      </div>
                                  </div>

                                  <div className={`tab-pane ${(tabActive === 'export-tab') ? 'active' : ''}`} id="export-tab" role="tabpanel" aria-labelledby="export-tab-fill">
                                      <div className="card">
                                          <div className="card-header border-bottom">
                                              <h4 className="card-title">Daten exportieren  {(organization.name)? `-> ${organization.name}`: ""}</h4>
                                              
                                          </div>
                                          <div className="card-body">

                                              <div className="col-12 mt-2">
                                                  <table className="table">
                                                      <thead></thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>
                                                                  <button className={`btn btn-${(!!organization._id)?"success":"danger"} ms-1`} disabled={(!!organization._id)?false:true} onClick={() => {exportData()}}>Daten exportieren</button>
                                                              </td>
                                                              <td>
                                                                  <h4>Daten-Export</h4>
                                                                  <p>
                                                                      Daten für Nutzer-Mieteinheiten , Zähler und Gateways können über die Plattform selbst oder per Datei-Upload erstellt und geändert werden.
                                                                      Bevor Sie eine neue Datei hochladen, ist es zwingend notwendig, dass Sie vorher zuerst die vorhandenen Daten über den Button "Daten exportieren" herunterladen.
                                                                      Die damit erstellte Datei ist eine <b>Strichpunkt-separierte</b> CSV-Datei und mit den gängigen Tabellen-Kalkulationsprogrammen  kompatibel.
                                                                      Diese Datei können Sie dann verändern und erneut hochladen.
                                                                  </p>
                                                                  <p>
                                                                      <ol>
                                                                          <li>Richtigen Kunden auswählen</li>
                                                                          <li>Export-Daten sind verfügbar, wenn Sie die benötigten Rechte haben</li>
                                                                          <li>Ihr Browser speichert die Datei im Downloads-Ordner</li>
                                                                      </ol>
                                                                  </p>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>

                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </section>

              </div>
          </Fragment>
      )
  }

  return (
      <Fragment>
        {UI()}
      </Fragment>
  ) 
}


DataManager2.requiredScope = ['tool.datamanager.any'];
DataManager2.requireAuth = true;
export default DataManager2;
