import React, {Fragment, useState, useEffect, useContext} from 'react';
import { useRouter } from "../../Shared/Router/Router";
import {NavLink} from "react-router-dom";
import { Link } from "react-router-dom";
import { AuthContext } from '../../../context/Auth/AuthProvider';
import {PermissionsWrapper, hasPermission} from "../reactPermissionsWrapper";
import $ from 'jquery'

const MenuEntry = ({children, href, title, id, icon="", isParent=false}) => {
    let childs = React.Children.toArray(children);
    let doRenderChildren = isParent && childs.length > 0;

    if(childs.length <= 0 && isParent && !href) {
        return (<Fragment></Fragment>)
    }

    let parentClasses = isParent? " nav-link d-flex align-items-center ":" dropdown-item d-flex align-items-center ";
    let dashboardClass = title == "Dashboard" ? " dashboard_nav_item" : ""

    if(doRenderChildren)
        parentClasses +=" dropdown-toggle";

    const LinkContent = ({href}) => {
        const router = useRouter();

        return (
            <a className={parentClasses + dashboardClass }  href={href} onClick={(e)=>{
                e.preventDefault();
                if(href) {
                    router.push(href);
                }
                //

                //fix the menu in desktop
                document?.querySelectorAll('.dropdown-menu.show').forEach((item)=>item.classList.remove('show'));

                if(document?.body?.clientWidth < 1000) {
                    //fix the menu in when menu is in responsive mode
                    document?.querySelectorAll('.dropdown-toggle.show').forEach((item)=>item.classList.remove('show'));
                    e.target?.closest('.dropdown-toggle')?.classList?.add('show');
                    e.target?.closest('li.dropdown')?.querySelector('ul.dropdown-menu')?.classList?.add('show');
                }
                // fix sub-mneu not closing on selection in responsive mode
                if(href !== undefined && document?.body?.clientWidth < 1000 ) {
                    e.target?.closest('li.dropdown')?.querySelector('ul.dropdown-menu')?.classList?.remove('show');
                   // document.getElementById('mainmenu').classList?.remove('show')
                }
                //}
            }} data-bs-trigger={doRenderChildren&&"hover focus"} data-bs-toggle={doRenderChildren&&"dropdown"}>
                {icon}
                <span>{title}</span>
            </a>
        )
    }
    return (
        <li className={doRenderChildren?"dropdown nav-item":"nav-item"} id={id} data-bs-menu={doRenderChildren&&"dropdown"} >
            {href?<a href={href}>
                    <LinkContent href={href}/>
                </a>
                :<LinkContent/>
            }
            {doRenderChildren&&
                <ul className="dropdown-menu" data-bs-popper="none">
                    {children}
                </ul>
            }
        </li>
    )
}

const MainMenuContent = ({children}) => {

    return (
        // <div className="horizontal-menu-wrapper">

        // <div className="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border container-fluid" role="navigation" data-bs-menu="menu-wrapper" data-bs-menu-type="floating-nav">
        <div className="w-100 header-navbar navbar-expand-sm navbar navbar-horizontal collapse navbar-collapse" id="mainmenu" role="navigation" data-bs-menu="menu-wrapper">
            {/* <div className="shadow-bottom"></div> */}
            {/* Horizontal menu content */}
            <div className="navbar-container main-menu-content" data-bs-menu="menu-container">
                <ul className="nav navbar-nav" id="main-menu-navigation" data-bs-menu="menu-navigation">
                    {children}
                </ul>
            </div>

        </div>
        // </div>
    )
}

const MainMenu = ({children}) => {

    const {user} = useContext(AuthContext);
    const router = useRouter();

    useEffect( () => {
        
    }, [user]);

    return (
        <Fragment>
            {user ?
                <MainMenuContent>
                    <PermissionsWrapper
                        user={user}
                        scopes={['dashboard.view.own']}
                    >
                        <MenuEntry isParent={true} href="/dashboards/analytics" id="main_menu_item_Dashboard" title="Dashboard" icon={<i className="fa-regular fa-chart-simple" />} />
                    </PermissionsWrapper>
                    {/* APPS MENU */}
                    <MenuEntry isParent={true} title="Apps"  id="menu_item_Apps" icon={<i className="fa-regular fa-table-tree" />}>
                        <PermissionsWrapper
                            user={user}
                            scopes={['location.view.own', 'location.view.any']}
                        >
                            <MenuEntry href="/apps/locations" title="Nutzer-/Mieteinheiten" icon={<i className="fa-solid fa-home" />} />
                        </PermissionsWrapper>
                        
                        <PermissionsWrapper
                            user={user}
                            scopes={['gateway.view.own', 'gateway.view.any']}
                        >
                            <MenuEntry href="/apps/gateways" title="Gateways"  icon={<i className="fa-solid fa-router" />} />
                        </PermissionsWrapper>
                        
                        <PermissionsWrapper
                            user={user}
                            scopes={['sensor.view.own']}
                        >
                            <MenuEntry href="/apps/sensors" title="Zähler" icon={<i className="fa-solid fa-microchip" />} />
                        </PermissionsWrapper>
                        
                        <PermissionsWrapper
                            user={user}
                            scopes={['tenant.view.any', 'tenant.view.own']}
                        >
                            <MenuEntry href="/apps/tenants" title="Nutzerwechsel" icon={<i className="fa-solid fa-people-roof" />} />
                        </PermissionsWrapper>
                        
                    </MenuEntry>
                        
                    {/* Verwaltung MENU */}
                    <PermissionsWrapper
                        user={user}
                        scopes={['user.view.any', 'organization.view.any', 'permission.view.any', 'client.view.any']}>
                        <MenuEntry isParent={true} title="Verwaltung" id="menu_item_Administrator" icon={<i className="fa-regular fa-gear" />}>
                            <PermissionsWrapper
                                user={user}
                                scopes={['user.view.any', 'user.update.any']}
                            >
                                <MenuEntry href="/admin/users" title="Benutzer" icon={<i className="fa-solid fa-users" />} />
                            </PermissionsWrapper>

                            <PermissionsWrapper
                                user={user}
                                roles={['realm_manager', 'realm_admin']}
                            >
                                <MenuEntry href="/admin/groups" title="Gruppen" icon={<i className="fa-solid fa-ball-pile" />} />
                            </PermissionsWrapper>

                            <PermissionsWrapper
                                user={user}
                                roles={['realm_admin', 'realm_manager']}
                            >
                                <MenuEntry href="/admin/organizations" title="Kunden" icon={<i className="fa-solid fa-people-group" />} />
                            </PermissionsWrapper>

                            <PermissionsWrapper
                                user={user}
                                scopes={['permission.view.any']}
                            >
                                <MenuEntry href="/admin/permissions" title="Berechtigungen" icon={<i className="fa-solid fa-square-check" />} />
                            </PermissionsWrapper>

                            <PermissionsWrapper
                                user={user}
                                scopes={['client.view.any']}
                            >
                                <MenuEntry href="/admin/clients" title="API-Zugangsverwaltung" icon={<i className="fa-solid fa-key-skeleton-left-right" />} />
                            </PermissionsWrapper>

                        </MenuEntry>
                    </PermissionsWrapper>

                    {/* Tools MENU */}
                    <PermissionsWrapper
                        user={user}
                        roles={['realm_admin', 'realm_manager']}
                    >
                        <MenuEntry isParent={true} title="Tools"  id="menu_item_Tools" icon={<i className="fa-regular fa-screwdriver-wrench" />}>
                            <PermissionsWrapper
                                user={user}
                                scopes={['search.view.any']}
                            >
                                <MenuEntry href="/tools/search" title="Suchfunktion" icon={<i className="fa-solid fa-search"></i>} />
                            </PermissionsWrapper>

                            <PermissionsWrapper
                                user={user}
                                scopes={['tool.generic.own', 'tool.generic.any']}
                            >
                                <MenuEntry href="/tools/convert/bsonjson" title="BSON Converter" icon={<i className="fa-solid fa-venus-mars"></i>} />
                            </PermissionsWrapper>
                            <PermissionsWrapper
                                user={user}
                                scopes={['tool.gateway.own', 'tool.gateway.any']}
                            >
                                <MenuEntry href="/tools/gateway/analyzer" title="Gateway-Analyse" icon={<i className="fa-solid fa-chart-scatter"></i>} />
                            </PermissionsWrapper>
                            
                            <PermissionsWrapper
                                user={user}
                                scopes={['tool.datamanager.any']}
                            >
                                <MenuEntry href="/tools/datamanager" title="Daten-Import" icon={<i className="fa-solid fa-download" />} />
                            </PermissionsWrapper>
                            
                            <PermissionsWrapper
                                user={user}
                                scopes={['email.view.any']}
                            >
                                <MenuEntry href="/tools/emaillogs" title={"Email Logs"} icon={<i className="fa-solid fa-envelopes" />} />
                            </PermissionsWrapper>

                            <PermissionsWrapper
                                user={user}
                                scopes={['sensor.update.any']}
                            >
                                <MenuEntry href="/tools/sensor/change" title={"Sensor Austausch Tool"} icon={<i className="text-danger fa-solid fa-repeat" />} />
                            </PermissionsWrapper>

                        </MenuEntry>
                    </PermissionsWrapper>

                    <li className="nav-item" id="menu_item_report_collapse">
                        <a className=" nav-link d-flex align-items-center "  data-bs-toggle="offcanvas" data-bs-target="#reportsOffcanvas" aria-controls="reportsOffcanvas">
                            <i className="fa-regular fa-file-lines " id="main_menu_report_icon" /> Berichte
                        </a>
                    </li>

                </MainMenuContent>
                :
                <></>
            }
        </Fragment>


    )
}

export default MainMenu;

